import Input from '@mui/material/Input';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

interface Props {
  value: string;
  onValueChange: (value: boolean) => void;
}

const BooleanEditor = ({ value, onValueChange }: Props): JSX.Element => (
  <Select
    input={<Input />}
    value={value ? 'Ja' : 'Nein'}
    onChange={(event) => onValueChange(event.target.value === 'Ja')}
    style={{ width: '100%' }}
  >
    <MenuItem value="Ja">Ja</MenuItem>
    <MenuItem value="Nein">Nein</MenuItem>
  </Select>
);

export default BooleanEditor;
