import React, { useContext, useEffect } from 'react';
import ReceiptInput from '../../../../types/ReceiptInput.types';
import { Autocomplete, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import DataContext from '../../../../context/DataProvider/DataContext';

function ReceiptEditTax() {
  const { tax: taxList, loaderFun } = useContext(DataContext);
  const { setFieldValue, handleBlur, values, errors, touched } = useFormikContext<ReceiptInput>();

  useEffect(() => {
    if (taxList.length === 0) {
      loaderFun.loadTax();
    }
  }, []);

  return (
    <Autocomplete
      id="ReceiptEditTaxLabel"
      fullWidth
      options={taxList}
      getOptionLabel={(tax) => `${tax.percent}% : ${tax.code}`}
      onChange={(e, value) => {
        setFieldValue('tax_id', value?.id || '');
      }}
      value={taxList.find((item) => item.id === values.tax_id) || null}
      onBlur={handleBlur}
      renderInput={(params) => (
        <TextField
          {...params}
          name="tax_id"
          label="Steuern"
          value={values.tax_id}
          error={Boolean(errors.tax_id && touched.tax_id) || false}
          helperText={errors.tax_id && touched.tax_id && errors.tax_id}
          required
        />
      )}
    />
  );
}

export default ReceiptEditTax;
