import { gql } from '@apollo/client';

const updateRank = gql`
  mutation ($variables: RankUpdate!, $id: Int!) {
    updateRank(input: $variables, id: $id) {
      id
      title
      is_manager
      is_default
    }
  }
`;
export default updateRank;
