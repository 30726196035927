import { number } from 'prop-types';

export default function ({
  added,
  changed,
  rows,
  refetch,
  createServices,
  updateServices,
  tax,
  bookingAccount,
  MONTHS,
}) {
  let changedRows;

  function validationEmptyString(stringForValidate) {
    if (stringForValidate) {
      const whiteSpace = /^\s*$/;
      if (!whiteSpace.test(stringForValidate)) {
        const stringReg = /^[a-zA-Z0-9_ ]*$/;
        return stringReg.test(stringForValidate);
      }
      return false;
    }
    return false;
  }

  function getTaxId(currentTaxValue) {
    const { id: taxId } = tax.find((elem) => elem.code === currentTaxValue);
    return taxId;
  }

  function getBookingAccountId(currentBookingAccountValue) {
    const { id: bookintAccountId } = bookingAccount.find((elem) => elem.title === currentBookingAccountValue);
    return bookintAccountId;
  }

  function getMonthId(currentMonthValue) {
    const { id: monthId } = MONTHS.find((elem) => elem.title === currentMonthValue);
    return monthId;
  }

  if (added) {
    const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
    changedRows = [
      ...rows,
      ...added.map((row, index) => ({
        id: startingAddedId + index,
        ...row,
      })),
    ];

    let currentService;
    let checkCurrentValues;

    if (changedRows[rows.length].type === 'YearlyService') {
      currentService = {
        title: changedRows[rows.length].title,
        type: changedRows[rows.length].type,
        default_price: parseInt(changedRows[rows.length].default_price, 10),
        tax_id: getTaxId(changedRows[rows.length].tax),
        fixed_start_month: getMonthId(changedRows[rows.length].fixed_start_month),
        booking_account_id: getBookingAccountId(changedRows[rows.length].booking_account),
      };

      checkCurrentValues =
        validationEmptyString(currentService.title) &&
        validationEmptyString(currentService.type) &&
        Number.isInteger(currentService.tax_id) &&
        Number.isInteger(currentService.default_price) &&
        Number.isInteger(currentService.fixed_start_month) &&
        Number.isInteger(currentService.booking_account_id);
    } else {
      currentService = {
        title: changedRows[rows.length].title,
        type: changedRows[rows.length].type,
        default_price: parseInt(changedRows[rows.length].default_price, 10),
        tax_id: getTaxId(changedRows[rows.length].tax),
        booking_account_id: getBookingAccountId(changedRows[rows.length].booking_account),
      };
      checkCurrentValues =
        validationEmptyString(currentService.title) &&
        validationEmptyString(currentService.type) &&
        Number.isInteger(currentService.tax_id) &&
        Number.isInteger(currentService.default_price) &&
        Number.isInteger(currentService.booking_account_id);
    }
    if (checkCurrentValues) {
      createServices({ variables: { variables: currentService } }).then((resultat) => {
        if (resultat.data) refetch();
      });
    }
  }

  if (changed) {
    const currentRows = [...rows];
    changedRows = rows.map((row) => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
    let editRowID;
    let editRow = {};
    let checkCurrentValues;
    for (let i = 0; i < currentRows.length; i += 1) {
      if (currentRows[i] !== changedRows[i]) {
        editRowID = changedRows[i].id;
        const taxType = typeof changedRows[i].tax;
        if (changedRows[i].type === 'YearlyService') {
          editRow = {
            title: changedRows[i].title,
            default_price: parseInt(changedRows[i].default_price, 10),
            type: changedRows[i].type,
            tax_id: getTaxId(taxType === 'object' ? changedRows[i].tax.percent : changedRows[i].tax),
            fixed_start_month: changedRows[i].fixed_start_month,
            booking_account_id: changedRows[i].booking_account.id,
          };
          checkCurrentValues =
            validationEmptyString(editRow.title) &&
            validationEmptyString(editRow.type) &&
            Number.isInteger(editRow.tax_id) &&
            Number.isInteger(editRow.default_price) &&
            Number.isInteger(editRow.fixed_start_month) &&
            Number.isInteger(editRow.booking_account_id);
        } else {
          editRow = {
            title: changedRows[i].title,
            default_price: parseInt(changedRows[i].default_price, 10),
            type: changedRows[i].type,
            tax_id: getTaxId(taxType === 'object' ? changedRows[i].tax.percent : changedRows[i].tax),
            booking_account_id: changedRows[i].booking_account.id,
          };

          checkCurrentValues =
            validationEmptyString(editRow.title) &&
            validationEmptyString(editRow.type) &&
            Number.isInteger(editRow.tax_id) &&
            Number.isInteger(editRow.default_price) &&
            Number.isInteger(editRow.booking_account_id);
        }
      }
    }
    if (checkCurrentValues) {
      updateServices({ variables: { variables: editRow, id: editRowID } }).then((resultat) => {
        if (resultat.data) refetch();
      });
    }
  }
}
