import React, { useContext, useEffect, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Country } from '../../../../../types/Country.types';
import onlyWhitespaceInString from '../../../../../services/onlyWhitespaceInString';
import DataContext from '../../../../../context/DataProvider/DataContext';
import titleIsIdentical from '../../../../../services/titleIsIdentical';

interface Props {
  value: string | undefined;
  onValueChange: (value: string) => void;
}

function validateValue(
  inputValue: Props['value'],
  currentValue: string | undefined,
  listOfData: Country[],
): { error: boolean; helperText: string } {
  let nameIsIdentical = titleIsIdentical({ inputValue, currentValue, listOfData });

  if (!inputValue) {
    return {
      error: true,
      helperText: 'Pflichtfeld',
    };
  } else if (onlyWhitespaceInString(inputValue)) {
    return {
      error: true,
      helperText: 'Darf nicht nur aus Leerzeichen bestehen',
    };
  } else if (inputValue.length > 128) {
    return {
      error: true,
      helperText: 'Max 128 Zeichen lang',
    };
  } else if (nameIsIdentical) {
    return {
      error: true,
      helperText: 'Titel ist bereits vorhanden',
    };
  } else {
    return {
      error: false,
      helperText: '',
    };
  }
}

const TextEditor = ({ value, onValueChange }: Props) => {
  const { country } = useContext(DataContext);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');

  const { current: initialValue } = useRef(value);

  useEffect(() => {
    const valid = validateValue(value, initialValue, country);
    setError(valid.error);
    setHelperText(valid.helperText);
  }, [value]);

  return (
    <TextField
      value={value || ''}
      onChange={(event) => onValueChange(event.target.value)}
      error={error}
      helperText={helperText}
    />
  );
};

export default TextEditor;
