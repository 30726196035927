import React from 'react';
import TextField from '@mui/material/TextField';
import { useFormikContext } from 'formik';
import ReceiptInput from '../../../../types/ReceiptInput.types';

function ReceiptEditPrice() {
  const { handleChange, handleBlur, values, errors, touched } = useFormikContext<ReceiptInput>();
  return (
    <TextField
      name="price"
      fullWidth
      required
      id="ReceiptEditPrice"
      label="Betrag"
      variant="filled"
      value={values.price || ''}
      type="number"
      onChange={handleChange}
      onBlur={handleBlur}
      error={Boolean(errors.price && touched.price) || false}
      helperText={errors.price && touched.price && errors.price}
    />
  );
}

export default ReceiptEditPrice;
