const columns = [
  { name: 'name', title: 'First Name' },
  { name: 'surname', title: 'Last Name' },
  { name: 'email_corp', title: 'Email' },
  { name: 'address', title: 'Address1' },
  { name: 'zip', title: 'Zip' },
  { name: 'city', title: 'City' },
  { name: 'phone', title: 'Phone' },
];

export default columns;

