import { Agent } from '../../../types/Agent.types';

interface MyObjectExtended extends Agent {
  month_of_birthday: string;
}

function sortByBirthdayMonth(agent: MyObjectExtended[]): MyObjectExtended[] {
  const monthNames = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ];

  return agent.sort((a, b) => {
    const monthIndexA = monthNames.indexOf(a.month_of_birthday);
    const monthIndexB = monthNames.indexOf(b.month_of_birthday);

    if (monthIndexA < monthIndexB) {
      return -1;
    }
    if (monthIndexA > monthIndexB) {
      return 1;
    }
    return 0;
  });
}

export default sortByBirthdayMonth;
