import { gql } from '@apollo/client';

const getClosingDataPayrollPeriod = gql`
  query {
    getClosingDataPayrollPeriod
  }
`;

export default getClosingDataPayrollPeriod;

