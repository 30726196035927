import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { url } from './config/config';

export default function useRequest<T = unknown>(): {
  isLoading: boolean;
  response: T | null;
  error: unknown | null;
  request: (typeQuery: 'query' | 'mutation', bodys: string | FormData, variables: string | object) => Promise<void>;
} {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState<T | null>(null);
  const [error, setError] = useState<unknown | null>();

  const сheckingQueryExecutionError = useCallback((responseBody: any) => {
    if (responseBody.errors) {
      const array: any = [];
      responseBody.errors.forEach((element: any) => {
        array.push(element.message.split('::Error:').splice(-1)[0]);
      });

      return { error: array };
    }
    setIsLoading(false);
    setResponse({ ...responseBody.data });
    return null;
  }, []);

  const request = useCallback(
    async (typeQuery: 'query' | 'mutation', body: string | FormData, variables: string | object) => {
      setIsLoading(true);
      if (typeQuery === 'query') {
        const queryBody = `{"query":"query{ ${body.toString()} }","variables":{},"operationName":null}`;
        return axios
          .request({
            url,
            method: 'POST',
            headers: {
              authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
            },
            data: queryBody,
          })
          .then((result) => {
            сheckingQueryExecutionError(result.data);
          })
          .catch((err) => {
            console.error('requestToServer data Error:', err);
          });
      }
      const MutationQueryBody = `{"query":"${body.toString()}","variables":{},"operationName":null}`;
      return axios
        .request({
          url,
          method: 'POST',
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
            // 'Content-Type': 'multipart/form-data',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          },
          data: MutationQueryBody,
        })
        .then((result) => {
          сheckingQueryExecutionError(result.data);
        })
        .catch((err) => {
          setError(err.toString());
          console.error('requestToServer data Error:', err);
        });
    },
    [],
  );

  return { isLoading, response, error, request };
}
