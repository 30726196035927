function dateToServices(serviceType, date, startOrEndDate) {
  let newDate = date ? new Date(date) : new Date();
  if (serviceType === 'MonthlyService' || serviceType === 'YearlyService') {
    // newDate.setFullYear((newDate.getFullYear()), (newDate.getMonth() + 1), 1);
    // newDate.setFullYear(newDate.getFullYear(), newDate.getMonth() + 1, 0); // last day of the month

    if (startOrEndDate === 'endDate') {
      newDate.setFullYear(newDate.getFullYear(), newDate.getMonth() + 1, 0); // last day of the month
    } else {
      newDate.setFullYear(newDate.getFullYear(), newDate.getMonth(), 1); // first day of the month
    }
  }
  let arr = newDate.toISOString().split('T');
  return arr[0];
}

export default dateToServices;
