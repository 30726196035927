import { gql } from '@apollo/client';

export default gql`
  query {
    getAllAgents {
      id
      branch_office {
        id
        title
      }
      rank {
        id
        title
        is_manager
      }
      status {
        id
        title
      }
      manager {
        id
        name
        surname
      }
      level {
        id
        title
      }
      contract_type {
        id
        title
      }
      name
      surname
      birthday
      is_male
      photo
      city
      address
      zip
      city2
      address2
      zip2
      phone
      mobile
      fax
      email_corp
      email_private
      agent_id
      jackplus_id
      business_owner
      business_registration_date
      bank_name
      tax_nr
      ust_nr
      is_small_business
      iban
      bic
      country {
        id
        title
      }
      country2 {
        id
        title
      }
      dismissal_reason
      start_date
      agent_account_nr
      dismissed_at
      worker_passport_give_date
      reverse_charge
      lat
      lon
    }
  }
`;
