export default [
  { columnName: 'id', width: 100 },
  {
    columnName: 'author_id',
    width: 100,
  },
  {
    columnName: 'agent_id',
    width: 100,
  },
  {
    columnName: 'surname',
    width: 150,
  },
  {
    columnName: 'name',
    width: 150,
  },
  {
    columnName: 'booking_account_id',
    width: 200,
  },
  {
    columnName: 'tax_id',
    width: 100,
  },
  { columnName: 'price', width: 100 },
  { columnName: 'description', width: 'auto' },
  {
    columnName: 'exported_at',
    width: 80,
  },
  {
    columnName: 'month',
    width: 80,
  },
  { columnName: 'year', width: 80 },
  {
    columnName: 'type',
    width: 100,
  },
];
