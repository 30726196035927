import { createContext } from 'react';
import { BranchOffice } from '../../types/BranchOffice.types';
import { Agent } from '../../types/Agent.types';
import { Level } from '../../types/Level.types';
import { Rank } from '../../types/Rank.types';
import { Receipt } from 'global-intern-types';
import { BookingAccount } from '../../types/BookingAccount.types';
import { User } from '../../types/User.types';
import { Status } from '../../types/Status.types';
import { ContractType } from '../../types/ContractType.types';
import { Country } from '../../types/Country.types';
import { Tax } from '../../types/Tax.types';

export interface InitialValuesType {
  dateLastPayroll: string;
  brachOffices: BranchOffice[];
  agents: Agent[];
  level: Level[];
  rank: Rank[];
  receipt: Receipt[];
  bookingAccount: BookingAccount[];
  user: User[];
  status: Status[];
  contractType: ContractType[];
  country: Country[];
  tax: Tax[];
  reloadData: () => void;
  loaderFun: {
    loadLevel: () => void;
    loadRank: () => void;
    loadTax: () => void;
    loadCountry: () => void;
    loadReceipt: () => void;
    loadBookingAccount: () => void;
    loadUser: () => void;
    loadStatus: () => void;
    loadBranchOffice: () => void;
    loadContractType: () => void;
    changeReceipts: (receipts: Receipt[]) => void;
  };
}

export const initialValues: InitialValuesType = {
  dateLastPayroll: '01-01-2000',
  brachOffices: [],
  agents: [],
  level: [],
  rank: [],
  receipt: [],
  bookingAccount: [],
  user: [],
  status: [],
  contractType: [],
  country: [],
  tax: [],
  reloadData: () => {},
  loaderFun: {
    loadLevel: () => {},
    loadRank: () => {},
    loadTax: () => {},
    loadCountry: () => {},
    loadReceipt: () => {},
    loadBookingAccount: () => {},
    loadUser: () => {},
    loadStatus: () => {},
    loadBranchOffice: () => {},
    loadContractType: () => {},
    changeReceipts: () => {},
  },
};

const DataContext = createContext<InitialValuesType>(initialValues);

export default DataContext;
