import React, { useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Table as DevExpressTable } from '@devexpress/dx-react-grid-material-ui';
import PropTypes from 'prop-types';
import requestToServer from '../../services/requestToServer';
import serviceToReceipt from '../../services/requestToServerSchemas/mutationSchemas/serviceToReceipts';
import sendAllToAccounting from '../../services/requestToServerSchemas/querySchemas/sendAllToAccounting';
import closingPeriod from '../../services/requestToServerSchemas/mutationSchemas/closingPeriod';
import getAllExportedFileInAccounting from '../../services/requestToServerSchemas/querySchemas/getAllExportedFileInAccounting';
import { URLForDownloadCloseAccountingSCV } from '../../services/config/URLForDownloadCloseAccountingCSV';
import getClosingStatusPayrollPeriod from '../../services/requestToServerSchemas/querySchemas/getClosingStatusPayrollPeriod';
import getServiceToReceiptStatus from '../../services/requestToServerSchemas/querySchemas/getServiceToReceiptStatus';
import getSendAllToAccountingStatus from '../../services/requestToServerSchemas/querySchemas/getSendAllToAccountingStatus';
import getClosingPeriodStatus from '../../services/requestToServerSchemas/querySchemas/getClosingPeriodStatus';
import Table from '../Table';
import { Typography } from '@mui/material';

function ListOfDownloadLink({ listOfDownloadLinks }: { listOfDownloadLinks: string[] }) {
  const linkForCSVFiles = URLForDownloadCloseAccountingSCV;
  const listOfAccountingReports =
    Array.isArray(listOfDownloadLinks) && listOfDownloadLinks.length > 0
      ? listOfDownloadLinks.map((fileName, index) => ({ id: index, fileName: fileName }))
      : [];

  const handleCopyToClipboard = (fileName: string) => {
    const fileUrl = `http://${linkForCSVFiles}/${fileName}`;
    navigator.clipboard.writeText(fileUrl).then(
      () => {
        alert('URL wurde in die Zwischenablage kopiert!');
      },
      (err) => {
        console.error('Fehler beim Kopieren der URL: ', err);
      },
    );
  };

  const TableRow = useCallback(
    ({ row, children, tableRow }: DevExpressTable.DataRowProps) => (
      <DevExpressTable.Row
        key={row.id}
        tableRow={tableRow}
        row={row}
        onClick={() => handleCopyToClipboard(row.fileName)}
        style={{ cursor: 'pointer' }}
      >
        {children}
      </DevExpressTable.Row>
    ),
    [],
  );

  return (
    <Table
      rowComponent={TableRow}
      row={listOfAccountingReports.reverse()}
      columns={[
        {
          name: 'fileName',
          title: 'Datei Name',
        },
      ]}
    />
  );
}

function SubmitComponent({
  title,
  handler,
  loading,
  error,
  disabledB,
  success,
}: {
  title: string;
  handler: () => void;
  loading: boolean;
  error: boolean;
  disabledB: boolean;
  success: boolean;
}) {
  return (
    <>
      <Typography variant="h4">{title}</Typography>
      <Button onClick={handler} variant="contained" size="medium" fullWidth disabled={!!disabledB}>
        {loading ? <CircularProgress /> : 'START'}
      </Button>
      <Typography variant="h3" color={error ? 'red' : '#27AE60'}>
        {error ? 'Error!' : ''}
        {success ? 'Success!' : ''}
      </Typography>
    </>
  );
}

SubmitComponent.propTypes = {
  title: PropTypes.string,
  handler: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  disabledB: PropTypes.bool,
  success: PropTypes.bool,
};

function MontlyAccouting() {
  const [update, setupdate] = useState(false);

  const [servicesToReceipts, setServicesToReceipts] = useState({
    loading: false,
    disabledB: true,
    success: false,
    error: false,
  });
  const [sendReceiptToAccouting, setSendReceiptToAccouting] = useState({
    loading: false,
    disabledB: true,
    success: false,
    error: false,
  });
  const [closeMontlyAccounting, setCloseMontlyAccounting] = useState({
    loading: false,
    disabledB: true,
    success: false,
    error: false,
  });
  const [listOfLinks, setListOfLinks] = useState(['']);

  useEffect(() => {
    requestToServer('query', getAllExportedFileInAccounting)
      .then((res) => {
        setListOfLinks(res.getAllExportedFileInAccounting);
      })
      .catch((error) => {
        console.error('requestToServer data Error in added: ', error);
        return [];
      });
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    getCurrentAccountingStatus();
  }, [update]);

  function getStatusForServiceToReceipt() {
    function ab() {
      requestToServer('query', getServiceToReceiptStatus)
        .then((res) => {
          if (res.getClosingStatusPayrollPeriod.servicesToReceipts === 'done') {
            setServicesToReceipts({
              loading: false,
              disabledB: true,
              error: false,
              success: true,
            });
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            clearInterval(t);
          }
        })
        .catch((error) => {
          console.error('requestToServer data Error in added: ', error);
          return [];
        });
    }
    let t = setInterval(ab, 5000);
  }

  function getStatusForSendAllToAccounting() {
    function ab() {
      requestToServer('query', getSendAllToAccountingStatus)
        .then((res) => {
          if (res.getClosingStatusPayrollPeriod.sendAllToAccounting === 'done') {
            setSendReceiptToAccouting({
              loading: false,
              disabledB: true,
              error: false,
              success: true,
            });
            setCloseMontlyAccounting({
              loading: false,
              disabledB: false,
              success: false,
              error: false,
            });
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            clearInterval(t);
          }
        })
        .catch((error) => {
          console.error('requestToServer data Error in added: ', error);
          return [];
        });
    }
    let t = setInterval(ab, 5000);
  }

  function getStatusForClosingPeriod() {
    function ab() {
      requestToServer('query', getClosingPeriodStatus)
        .then((res) => {
          if (res.getClosingStatusPayrollPeriod.closingPeriod === 'done') {
            setSendReceiptToAccouting({
              loading: false,
              disabledB: true,
              error: false,
              success: true,
            });
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            clearInterval(t);
          }
        })
        .catch((error) => {
          console.error('requestToServer data Error in added: ', error);
          return [];
        });
    }
    let t = setInterval(ab, 5000);
  }

  function getCurrentAccountingStatus() {
    requestToServer('query', getClosingStatusPayrollPeriod)
      .then((res) => {
        /* servicesToReceipt */
        if (res.getClosingStatusPayrollPeriod.servicesToReceipts === 'not running') {
          setServicesToReceipts({
            loading: false,
            disabledB: false,
            error: false,
            success: false,
          });
        }
        if (res.getClosingStatusPayrollPeriod.servicesToReceipts === 'running') {
          setServicesToReceipts({
            loading: true,
            disabledB: true,
            error: false,
            success: false,
          });
          getStatusForServiceToReceipt();
        }
        if (res.getClosingStatusPayrollPeriod.servicesToReceipts === 'done') {
          setServicesToReceipts({
            loading: false,
            disabledB: true,
            error: false,
            success: true,
          });
        }
        /* receitpToAccounting */
        if (res.getClosingStatusPayrollPeriod.sendAllToAccounting === 'not running') {
          setSendReceiptToAccouting({
            ...sendReceiptToAccouting,
          });
        }
        if (
          res.getClosingStatusPayrollPeriod.sendAllToAccounting === 'not running' &&
          res.getClosingStatusPayrollPeriod.servicesToReceipts === 'done'
        ) {
          setSendReceiptToAccouting({
            loading: false,
            disabledB: false,
            success: false,
            error: false,
          });
        }
        if (res.getClosingStatusPayrollPeriod.sendAllToAccounting === 'running') {
          setSendReceiptToAccouting({
            loading: true,
            disabledB: true,
            error: false,
            success: false,
          });
          getStatusForSendAllToAccounting();
        }
        if (res.getClosingStatusPayrollPeriod.sendAllToAccounting === 'done') {
          setSendReceiptToAccouting({
            loading: false,
            disabledB: true,
            error: false,
            success: true,
          });
        }
        /* closingPeriod */
        if (res.getClosingStatusPayrollPeriod.closingPeriod === 'not running') {
          setCloseMontlyAccounting({
            ...closeMontlyAccounting,
          });
        }
        if (
          res.getClosingStatusPayrollPeriod.closingPeriod === 'not running' &&
          res.getClosingStatusPayrollPeriod.sendAllToAccounting === 'done'
        ) {
          setCloseMontlyAccounting({
            loading: false,
            disabledB: false,
            success: false,
            error: false,
          });
        }
        if (res.getClosingStatusPayrollPeriod.closingPeriod === 'running') {
          setCloseMontlyAccounting({
            loading: true,
            disabledB: true,
            error: false,
            success: false,
          });
          getStatusForClosingPeriod();
        }
        if (res.getClosingStatusPayrollPeriod.closingPeriod === 'done') {
          setCloseMontlyAccounting({
            loading: false,
            disabledB: true,
            error: false,
            success: true,
          });
        }
        return res;
      })
      .catch((error) => {
        console.error('requestToServer data Error in added: ', error);
        return [];
      });
  }

  function handelServiceToReceipt() {
    setServicesToReceipts({
      loading: true,
      disabledB: true,
      error: false,
      success: false,
    });
    function ab() {
      requestToServer('mutation', serviceToReceipt)
        .then((res) => {
          if (res.servicesToReceipts === true) {
            setServicesToReceipts({
              loading: false,
              error: false,
              success: true,
              disabledB: true,
            });
            setSendReceiptToAccouting({
              loading: false,
              error: false,
              success: false,
              disabledB: false,
            });
          } else {
            setServicesToReceipts({
              loading: false,
              error: true,
              success: false,
              disabledB: true,
            });
          }
        })
        .catch((error) => {
          console.error('requestToServer data Error in added: ', error);
          return [];
        });
    }
    ab();
  }

  function handelReceiptToAccounting() {
    requestToServer('query', sendAllToAccounting);
    setSendReceiptToAccouting({
      loading: true,
      disabledB: true,
      error: false,
      success: false,
    });
    getStatusForSendAllToAccounting();
  }

  function handelCloseMonthlyAccounting() {
    setCloseMontlyAccounting({
      loading: true,
      disabledB: true,
      error: false,
      success: false,
    });

    function ab() {
      requestToServer('mutation', closingPeriod)
        .then((res) => {
          setupdate(!update);
          if (typeof res.closingPeriod !== 'undefined' || res.closingPeriod === null) {
            // setDownloadLink(res.closingPeriod);
            setCloseMontlyAccounting({
              loading: false,
              error: false,
              success: true,
              disabledB: true,
            });
          } else {
            setCloseMontlyAccounting({
              loading: false,
              error: true,
              success: false,
              disabledB: true,
            });
          }
        })
        .catch((error) => {
          console.error('requestToServer data Error in added: ', error);
          return [];
        });
    }
    ab();
  }

  return (
    <Box
      sx={{
        padding: '20px',
        minHeight: '80vh',
      }}
    >
      <Grid container spacing={1} xs={12}>
        <Grid item container xs={4} direction="row" alignItems="flex-start">
          <Grid item container xs={12} direction="row" alignItems="flex-start">
            <Grid container spacing={3} alignItems="center" alignContent="center">
              <Grid item xs={12} xl={10}>
                <SubmitComponent
                  title="1. Belege erstellen"
                  loading={servicesToReceipts.loading}
                  handler={handelServiceToReceipt}
                  disabledB={servicesToReceipts.disabledB}
                  error={servicesToReceipts.error}
                  success={servicesToReceipts.success}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} alignItems="center" alignContent="center">
              <Grid item xs={12} xl={10}>
                <SubmitComponent
                  title="2. Belege verarbeiten"
                  loading={sendReceiptToAccouting.loading}
                  handler={handelReceiptToAccounting}
                  disabledB={sendReceiptToAccouting.disabledB}
                  error={sendReceiptToAccouting.error}
                  success={sendReceiptToAccouting.success}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} alignItems="center" alignContent="center">
              <Grid item xs={12} xl={10}>
                <SubmitComponent
                  title="3. Abschluss fertigstellen"
                  loading={closeMontlyAccounting.loading}
                  handler={handelCloseMonthlyAccounting}
                  disabledB={closeMontlyAccounting.disabledB}
                  error={closeMontlyAccounting.error}
                  success={closeMontlyAccounting.success}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <ListOfDownloadLink listOfDownloadLinks={listOfLinks} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default MontlyAccouting;
