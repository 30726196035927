import React from 'react';
import ErrorBoundaries from '../../../components/ErrorBoundaries';
import AllTaxTable from './components/AllTaxTable';

function TaxPage() {
  return (
    <ErrorBoundaries>
      <AllTaxTable />
    </ErrorBoundaries>
  );
}

export default TaxPage;
