import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

interface Props {
  value: boolean | number;
}
function BooleanFormatter({ value }: Props): JSX.Element {
  if (value === true || value === 1) {
    return <CheckIcon data-testid="check-icon" />;
  }
  return <ClearIcon data-testid="clear-icon" />;
}

export default BooleanFormatter;
