import { gql } from '@apollo/client';

const getAllAgentFiles = gql`
  query ($agent_id: String!) {
    getAllAgentFiles(agent_id: $agent_id) {
      id
      filename
      name
      size
      path
    }
  }
`;

export default getAllAgentFiles;
