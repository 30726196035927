import deleteUnnecessarySpaces from '../../../../services/deleteUnnecessarySpaces';

function nameIsIdentical(inputValue, currentValue, listOfBookingAccounts) {
  let nameIsIdentical = false;
  for (let index = 0; index < listOfBookingAccounts.length; index++) {
    if (currentValue) {
      if (inputValue === currentValue) {
        return false;
      }
    }
    if (inputValue === listOfBookingAccounts[index].title) {
      return true;
    }
  }
  return nameIsIdentical;
}

function numberExists(inputValue, currentValue, listOfBookingAccounts) {
  let numberExists = false;
  for (let index = 0; index < listOfBookingAccounts.length; index++) {
    if (currentValue) {
      if (inputValue === currentValue) {
        return false;
      }
    }
    if (inputValue === listOfBookingAccounts[index].booking_account_nr) {
      return true;
    }
  }
  return numberExists;
}

function isNumberWith10Digits(num) {
  const numStr = num.toString();
  if (numStr.length === 10) {
    if (/^\d+$/.test(numStr)) {
      return true;
    }
  }

  return false;
}

export default function ({ added, changed, rows, loaderFun, createBookingAccount, updateBookingAccount }) {
  let changedRows;

  if (added) {
    const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
    changedRows = [
      ...rows,
      ...added.map((row, index) => ({
        id: startingAddedId + index,
        ...row,
      })),
    ];

    const currentBookingAccount = {
      title: undefined,
      booking_account_nr: undefined,
    };

    if (deleteUnnecessarySpaces(changedRows[rows.length].title)) {
      if (deleteUnnecessarySpaces(changedRows[rows.length].title).length > 255) {
        alert(
          'Buchungskonto kann nicht gespeichert werde da die maximale Zeichenanzahl von 255 Zeichen überschritten wurde',
        );
      } else if (
        nameIsIdentical(deleteUnnecessarySpaces(changedRows[rows.length].title), currentBookingAccount.title, rows)
      ) {
        alert('Buchungskonto kann nicht gespeichert da Titel bereits vorhanden ist');
      } else {
        currentBookingAccount.title = deleteUnnecessarySpaces(changedRows[rows.length].title);
      }
    } else {
      alert('Buchungskonto kann nicht gespeichert da es kein Titel enthält');
    }

    if (parseInt(changedRows[rows.length].booking_account_nr, 10)) {
      if (isNumberWith10Digits(parseInt(changedRows[rows.length].booking_account_nr, 10))) {
        alert('Buchungskonto muss bzw. darf nur 10 Zeichen enthalten');
      } else if (
        numberExists(
          parseInt(changedRows[rows.length].booking_account_nr, 10),
          currentBookingAccount.booking_account_nr,
          rows,
        )
      ) {
        alert('Buchungskontonummer bereits vorhanden');
      } else {
        currentBookingAccount.booking_account_nr = parseInt(changedRows[rows.length].booking_account_nr, 10);
      }
    } else {
      alert('Buchungskonto kann nicht gespeichert da es keine Buchungskontonummer enthält');
    }
    if (currentBookingAccount.title && currentBookingAccount.booking_account_nr) {
      createBookingAccount({ variables: { variables: currentBookingAccount } }).then((result) => {
        if (result.data.createBookingAccount) loaderFun.loadBookingAccount();
      });
    }
  }

  if (changed) {
    const currentRows = [...rows];
    changedRows = rows.map((row) => (changed[row.id] ? { ...row, ...changed[row.id] } : row));

    let editRowID;
    let editRow = {};
    let currentRow = {};
    for (let i = 0; i < currentRows.length; i += 1) {
      if (currentRows[i] !== changedRows[i]) {
        editRowID = changedRows[i].id;
        currentRow = { title: currentRows[i].title, booking_account_nr: currentRows[i].booking_account_nr };
        editRow = {
          title: deleteUnnecessarySpaces(changedRows[i].title),
          booking_account_nr: parseInt(changedRows[i].booking_account_nr, 10),
        };
      }
    }

    const editBookingAccount = {
      title: undefined,
      booking_account_nr: undefined,
    };

    if (editRow.title) {
      if (editRow.title.length > 255) {
        alert(
          'Buchungskonto kann nicht gespeichert werde da die maximale Zeichenanzahl von 255 Zeichen überschritten wurde',
        );
      } else if (nameIsIdentical(editRow.title, currentRow.title, rows)) {
        alert('Buchungskonto kann nicht gespeichert da Titel bereits vorhanden ist');
      } else {
        editBookingAccount.title = editRow.title;
      }
    } else {
      alert('Buchungskonto kann nicht gespeichert da es kein Titel enthält');
    }

    if (editRow.booking_account_nr) {
      if (isNumberWith10Digits(editRow.booking_account_nr)) {
        alert('Buchungskontonummer muss bzw. darf nur 10 Zeichen enthalten');
      } else if (numberExists(editRow.booking_account_nr, currentRow.booking_account_nr, rows)) {
        alert('Buchungskontonummer bereits vorhanden');
      } else {
        editBookingAccount.booking_account_nr = editRow.booking_account_nr;
      }
    } else {
      alert('Buchungskonto kann nicht gespeichert da es Buchungskontonummer enthält');
    }

    if (editBookingAccount.title && editBookingAccount.booking_account_nr) {
      updateBookingAccount({ variables: { variables: editBookingAccount, id: editRowID } }).then((result) => {
        if (result.data.updateBookingAccount) loaderFun.loadBookingAccount();
      });
    }
  }
}
