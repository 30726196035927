import React from 'react';
import { useMutation } from '@apollo/client';
import { Box,Button,Tooltip } from "@mui/material"
import AlertMessage from '../../AlertMessage';
import sendPasswordToAgentQuery from '../../../Apollo/mutations/sendPasswordToAgentQuery';

interface Props {
  isDisabled: boolean;
  id: string;
}

function SendEmailProject({ isDisabled = false, id }: Props) {
  const [sendPasswordToAgent, { data, error }] = useMutation(sendPasswordToAgentQuery);

  return (
    <>
      <Tooltip
        title={
          isDisabled ? 'Synchronisieren Sie den Berater mit der CentralNode' : 'Neues Password an Berater versenden'
        }
        arrow
      >
        <Button
          fullWidth
          onClick={() => {
            sendPasswordToAgent({ variables: { agentID: id } });
          }}
          variant="outlined"
          disabled={isDisabled}
        >
          Password generieren
        </Button>
      </Tooltip>
      <AlertMessage
        typeMessage={error && 'error'}
        alertMessage={error && 'Es ist ein Fehler aufgetreten'}
        isAlertMessage={!!error}
      />
      <AlertMessage
        typeMessage={data && !error && 'success'}
        alertMessage={data && !error && 'Berater wurde erfolgreich synchronisiert'}
        isAlertMessage={!!data && !error}
      />
    </>
  );
}

export default SendEmailProject;
