import deleteUnnecessarySpaces from '../../../../services/deleteUnnecessarySpaces';

function nameIsIdentical(inputValue, currentValue, listOfLevels) {
  let nameIsIdentical = false;
  for (let index = 0; index < listOfLevels.length; index++) {
    if (currentValue) {
      if (inputValue === currentValue) {
        return false;
      }
    }
    if (inputValue === listOfLevels[index].title) {
      return true;
    }
  }
  return nameIsIdentical;
}

const commitChanges = async ({ added, changed, rows, loaderFun, createLevel, updateLevel }) => {
  let changedRows;

  if (added) {
    const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
    changedRows = [
      ...rows,
      ...added.map((row, index) => ({
        id: startingAddedId + index,
        ...row,
      })),
    ];

    let currentLevel = {
      title: undefined,
      is_default: undefined,
    };

    if (deleteUnnecessarySpaces(changedRows[rows.length].title)) {
      if (deleteUnnecessarySpaces(changedRows[rows.length].title).length > 128) {
        alert('Level kann nicht gespeichert werde da die maximale Zeichenanzahl von 128 Zeichen überschritten wurde');
      } else if (nameIsIdentical(deleteUnnecessarySpaces(changedRows[rows.length].title), currentLevel.title, rows)) {
        alert('Lelvel kann nicht gespeichert da Titel bereits vorhanden ist');
      } else {
        currentLevel.title = deleteUnnecessarySpaces(changedRows[rows.length].title);
      }
    } else {
      alert('Level kann nicht gespeichert da es kein Titel enthält');
    }

    if (changedRows[rows.length].is_default !== undefined) {
      currentLevel.is_default = changedRows[rows.length].is_default;
    } else {
      alert('Level kann nicht gespeichert da es kein "is defaul" Wert vorhanden ist');
    }

    if (currentLevel.title && currentLevel.is_default !== undefined) {
      createLevel({ variables: { variables: currentLevel } }).then((result) => {
        if (result.data.createLevel) loaderFun.loadLevel();
      });
    }
  }

  if (changed) {
    let currentRows = [...rows];
    changedRows = rows.map((row) => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
    let editRowID;
    let editRow = {};
    let currentRow = {};
    for (let i = 0; i < currentRows.length; i++) {
      if (currentRows[i] !== changedRows[i]) {
        editRowID = changedRows[i].id;
        currentRow = { title: currentRows[i].title, is_default: changedRows[i].is_default };
        editRow = {
          title: deleteUnnecessarySpaces(changedRows[i].title),
          is_default: changedRows[i].is_default,
        };
      }
    }

    let editLevel = {
      title: undefined,
      is_default: undefined,
    };

    if (editRow.title) {
      if (editRow.title.length > 128) {
        alert('Level kann nicht gespeichert werde da die maximale Zeichenanzahl von 128 Zeichen überschritten wurde');
      } else if (nameIsIdentical(editRow.title, currentRow.title, rows)) {
        alert('Level kann nicht gespeichert da Titel bereits vorhanden ist');
      } else {
        editLevel.title = editRow.title;
      }
    } else {
      alert('Level kann nicht gespeichert da es kein Titel enthält');
    }

    if (editRow.is_default !== undefined) {
      editLevel.is_default = editRow.is_default;
    } else {
      alert('Level kann nicht gespeichert da es kein "is defaul" Wert vorhanden ist');
    }

    if (editLevel.title && editRow.is_default !== undefined) {
      updateLevel({ variables: { variables: editLevel, id: editRowID } }).then((result) => {
        if (result.data.updateLevel) loaderFun.loadLevel();
      });
    }
  }
};

export default commitChanges;
