import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

interface Props {
  value: string | undefined;
  onValueChange: (value: string) => void;
}

function NumberEditor({ value, onValueChange }: Props) {
  return (
    <Box>
      <TextField
        value={value || ''}
        type="number"
        onChange={(event) => onValueChange(event.target.value)}
        error={!value}
        helperText={value ? '' : 'Enter unique range'}
      />
    </Box>
  );
}

export default NumberEditor;
