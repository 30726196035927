export default function ({ added, changed, rows, refetch, createUser, updateUser }) {
  let changedRows;
  function isEmail(email) {
    if (email) {
      const emailReg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return emailReg.test(email);
    }
    return false;
  }
  function isPassword(passwordString) {
    if (passwordString) {
      const passwordReg = /^[\w&!@#$%^&*()_§+\-=\]{};':"\\|,.<>/ÄäÜüÖö?]+$/;
      return passwordReg.test(passwordString);
    }
    return false;
  }
  function validationEmptyString(stringForValidate) {
    if (stringForValidate) {
      const stringReg = /^(\w+\.?\w+)$/;
      return stringReg.test(stringForValidate);
    }
    return false;
  }

  if (added) {
    const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
    changedRows = [
      ...rows,
      ...added.map((row, index) => ({
        id: startingAddedId + index,
        ...row,
      })),
    ];

    const currentUser = {
      username: changedRows[rows.length].username,
      email: changedRows[rows.length].email,
      enabled: changedRows[rows.length].enabled === 'Yes',
      password: changedRows[rows.length].password,
      roles: changedRows[rows.length].roles,
    };
    if (
      validationEmptyString(currentUser.roles) &&
      validationEmptyString(currentUser.username) &&
      isEmail(currentUser.email) &&
      isPassword(currentUser.password)
    ) {
      createUser({ variables: { input: currentUser } }).then((resultat) => {
        if (resultat.data) refetch();
      });
    }
  }
  if (changed) {
    const currentRows = [...rows];
    changedRows = rows.map((row) => (changed[row.id] ? { ...row, ...changed[row.id] } : row));

    let editRow = {};
    let username;
    let isPasswordChange = false;
    for (let i = 0; i < currentRows.length; i += 1) {
      if (currentRows[i] !== changedRows[i]) {
        username = currentRows[i].username;
        if (currentRows[i].password !== changedRows[i].password) {
          isPasswordChange = true;
          editRow = {
            inputUsername: changedRows[i].username,
            email: changedRows[i].email,
            enabled: changedRows[i].enabled,
            password: changedRows[i].password,
            roles: changedRows[i].roles,
          };
        } else {
          editRow = {
            inputUsername: changedRows[i].username,
            email: changedRows[i].email,
            enabled: changedRows[i].enabled,
            roles: changedRows[i].roles,
          };
        }
      }
    }

    const withPasswordCheck =
      validationEmptyString(editRow.roles) &&
      validationEmptyString(editRow.inputUsername) &&
      isEmail(editRow.email) &&
      isPassword(editRow.password);

    const withoutPasswordCheck =
      validationEmptyString(editRow.roles) && validationEmptyString(editRow.inputUsername) && isEmail(editRow.email);

    if (isPasswordChange ? withPasswordCheck : withoutPasswordCheck) {
      updateUser({ variables: { variables: editRow, username } }).then((resultat) => {
        if (resultat.data) refetch();
      });
    }
  }
}

