import React from 'react';
import { Button, Grid } from '@mui/material';
const BrachofficeBtnContainer = ({ brachofficeData, setBrancheOfficeFilter, allFilterReset }) => (
  <>
    <Grid container direction={'row-reverse'}>
      <Button variant="contained" disableRipple onClick={allFilterReset}>
        FILTER ZURÜCKSETZEN
      </Button>
    </Grid>
  </>
);

export default BrachofficeBtnContainer;
