import { gql } from '@apollo/client';

const updateAgent = gql`
  mutation ($agent_id: Int!, $input: agentInput!) {
    updateAgent(agent_id: $agent_id, input: $input) {
      branch_office {
        id
      }
      rank {
        id
      }
      status {
        id
      }
      level {
        id
      }
      contract_type {
        id
      }
      agent_id
      name
      surname
      birthday
      is_male
      city
      address
      zip
      phone
      mobile
      fax
      email_corp
      email_private
      agent_id
      jackplus_id
      business_owner
      business_registration_date
      tax_nr
      ust_nr
      is_small_business
      iban
      bic
      notes
      worker_passport_give_date
      country {
        id
      }
      dismissal_reason
      start_date
      agent_account_nr
      dismissed_at
      #   branch_office_email_postfix
    }
  }
`;
export default updateAgent;
