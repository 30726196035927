import React from 'react';
import ServicesReport from '../../../components/ServicesReport/index';

type Props = {};

function ServicesReportPage({}: Props) {
  return <ServicesReport />;
}

export default ServicesReportPage;
