import React, { useEffect, useContext } from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { useMutation } from '@apollo/client';
import createLevelQuery from '../../../../Apollo/mutations/createLevel';
import updateLevelQuery from '../../../../Apollo/mutations/updateLevel';
import TextEditor from './components/TextEditor';
import Table from '../../../../components/Table/Table';
import columns from './columnDefinition';
import DataContext from '../../../../context/DataProvider/DataContext';
import commitChanges from './commitChanges';
import BooleanEditor from './components/BooleanEditor';
import BooleanFormatter from './services/booleanFormater';

const textColumns = ['title'];

function TextTypeProvider() {
  return <DataTypeProvider editorComponent={TextEditor} for={textColumns} />;
}

const booleanColumns = ['is_default'];

function BooleanTypeProvider() {
  return (
    <DataTypeProvider formatterComponent={BooleanFormatter} editorComponent={BooleanEditor} for={booleanColumns} />
  );
}

const Provider = [TextTypeProvider, BooleanTypeProvider];

function AllLevelsTable() {
  const { level, loaderFun } = useContext(DataContext);
  const [createLevel] = useMutation(createLevelQuery);
  const [updateLevel] = useMutation(updateLevelQuery);

  useEffect(() => {
    loaderFun.loadLevel();
  }, []);

  const editingStateColumnExtensions = [{ columnName: 'id', editingEnabled: false }];

  return (
    <Table
      row={level}
      columns={columns}
      defaultColumnWidths={columns.map((column) => ({ columnName: column.name, width: 'auto' }))}
      onCommitChanges={({ added, changed }) =>
        commitChanges({ added, changed, rows: level, loaderFun, createLevel, updateLevel })
      }
      columnExtensions={editingStateColumnExtensions}
      dataTypeProvider={Provider}
      editable
      showAddCommand
      showEditCommand
    />
  );
}

export default AllLevelsTable;
