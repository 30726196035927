function replaceUmlaute(params: string) {
  let cleardStr = params
    .replace(/\s/g, '-')
    .replace(/[ä]/g, 'ae')
    .replace(/[ü]/g, 'ue')
    .replace(/[ö]/g, 'oe')
    .replace(/[úùû]/g, 'u')
    .replace(/[é]/g, 'e')
    .replace(/[áàâ]/g, 'a')
    .replace(/[ß]/g, 'ss');
  return cleardStr;
}

export default replaceUmlaute;
