import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

interface Props {
  value: boolean | number;
  row?: any;
}
function BooleanFormatter({ value, row }: Props): JSX.Element {
  console.log('row', row);
  if (value === true || value === 1) {
    return <CheckIcon data-testid="Ja" />;
  }
  return <ClearIcon data-testid="Nein" />;
}

export default BooleanFormatter;
