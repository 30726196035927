import React from 'react';
import { ContractType } from '../types/ContractType.types';

interface Props {
  inputValue: string | undefined;
  currentValue: string | undefined;
  listOfData: any;
}

function titleIsIdentical({ inputValue, currentValue, listOfData }: Props): boolean {
  let nameIsIdentical = false;
  for (let index = 0; index < listOfData.length; index++) {
    if (currentValue) {
      if (inputValue === currentValue) {
        return false;
      }
    }
    if (inputValue === listOfData[index].title) {
      return true;
    }
  }
  return nameIsIdentical;
}

export default titleIsIdentical;
