import React, { useContext, useEffect } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import ReceiptInput from '../../../../types/ReceiptInput.types';
import { useFormikContext } from 'formik';
import DataContext from '../../../../context/DataProvider/DataContext';

function ReceiptEditBookingAccount() {
  const { bookingAccount, loaderFun } = useContext(DataContext);

  useEffect(() => {
    if (bookingAccount.length === 0) {
      loaderFun.loadBookingAccount();
    }
  }, []);

  const { setFieldValue, handleBlur, values, errors, touched } = useFormikContext<ReceiptInput>();
  return (
    <Autocomplete
      id="ReceiptEditBookingAccountLabel"
      fullWidth
      options={bookingAccount}
      getOptionLabel={(contractType) => `${contractType.title}`}
      onChange={(e, value) => {
        setFieldValue('booking_account_id', value?.id || '');
      }}
      value={bookingAccount.find((item) => item.id === values.booking_account_id) || null}
      onBlur={handleBlur}
      renderInput={(params) => (
        <TextField
          {...params}
          name="booking_account_id"
          label="Buchungskonto"
          error={Boolean(errors.booking_account_id && touched.booking_account_id) || false}
          helperText={errors.booking_account_id && touched.booking_account_id && errors.booking_account_id}
          required
        />
      )}
    />
  );
}

export default ReceiptEditBookingAccount;
