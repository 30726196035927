const columns = [
  { name: 'start_date', title: 'Startdatum' },
  { name: 'end_date', title: 'Enddatum' },
  { name: 'agent_service_price', title: 'Preis' },
  { name: 'agent_service_description', title: 'Beschreibung' },
  { name: 'service_default_price', title: 'Vorgegebener Preis' },
  { name: 'service_title', title: 'Titel' },
  { name: 'service_type', title: 'Typ' },
  { name: 'service_tax', title: 'Steuer %' },
  { name: 'customer_id', title: 'Kundennummer' },
];

export default columns;
