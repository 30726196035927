import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import columns from '../columnDefinition';
import BooleanFormatter from './AllUserTable/services/booleanFormater';
import BooleanEditor from './components/BooleanEditor';
import commitChanges from './commitChanges';
import TextEditor from './components/TextEditor';
import createUserQuery from '../../../../Apollo/mutations/createUser';
import getAllUser from '../../../../Apollo/queries/getAllUser';
import PrmUserInit from '../../../../types/initValues/PrmUser.init';
import Table from '../../../../components/Table';
import updateUserQuery from '../../../../Apollo/mutations/updateUser';
import PasswordEditor from './components/PasswordEditor';
import PasswordFormater from './AllUserTable/services/PasswordFormater';
import RolesEditor from './components/RolesEditor';
import AlertMessage from '../../../../components/AlertMessage';

const booleanColumns = ['enabled'];

function BooleanTypeProvider() {
  return (
    <DataTypeProvider formatterComponent={BooleanFormatter} editorComponent={BooleanEditor} for={booleanColumns} />
  );
}

const textColumns = ['username', 'email'];

function TextTypeProvider() {
  return <DataTypeProvider editorComponent={TextEditor} for={textColumns} />;
}

const passwordColumns = ['password'];

function PasswordProvider() {
  return (
    <DataTypeProvider formatterComponent={PasswordFormater} editorComponent={PasswordEditor} for={passwordColumns} />
  );
}

const rolesColums = ['roles'];

function RolesProvider() {
  return <DataTypeProvider for={rolesColums} editorComponent={RolesEditor} />;
}

const Provider = [BooleanTypeProvider, TextTypeProvider, PasswordProvider, RolesProvider];

function UserTable() {
  const [createUser, { data: dataCreateUser, error: errorCreateUser }] = useMutation(createUserQuery);
  const [updateUser, { data: dataUpdateUser, error: errorUpdateUser }] = useMutation(updateUserQuery);
  const { data: Users, refetch } = useQuery(getAllUser);
  const editingStateColumnExtensions = [
    { columnName: 'id', editingEnabled: false },
    { columnName: 'last_login', editingEnabled: false },
  ];

  return (
    <>
      <Table
        row={Users ? Users?.getAllUser : [PrmUserInit]}
        columns={columns}
        defaultColumnWidths={columns.map((column) => ({ columnName: column.name, width: 'auto' }))}
        onCommitChanges={({ added, changed }) =>
          commitChanges({ added, changed, rows: Users?.getAllUser, refetch, createUser, updateUser })
        }
        columnExtensions={editingStateColumnExtensions}
        dataTypeProvider={Provider}
        editable
        showAddCommand
        showEditCommand
      />
      <AlertMessage
        typeMessage={
          (dataCreateUser && !errorCreateUser && 'success') || (dataUpdateUser && !errorUpdateUser && 'success')
        }
        alertMessage={
          (dataCreateUser && !errorCreateUser && 'Benutzer wurde erfolgreich erstellt') ||
          (dataUpdateUser && !errorUpdateUser && 'Benutzerdaten erfolgreich geändert')
        }
        isAlertMessage={(!!dataCreateUser && !errorCreateUser) || (!!dataUpdateUser && !errorUpdateUser)}
      />
    </>
  );
}

export default UserTable;
