import { gql } from '@apollo/client';

const getAgentById = gql`
  query getAgentv2($agent_id: Int!) {
    getAgentv2(agent_id: $agent_id) {
      id
      branch_office_id
      rank_id
      status_id
      level_id
      contract_type_id
      manager_id
      name
      surname
      birthday
      is_male
      city
      address
      zip
      city2
      address2
      zip2
      phone
      mobile
      fax
      thumbnail
      photo
      email_corp
      email_private
      agent_id
      jackplus_id
      business_owner
      business_registration_date
      tax_nr
      ust_nr
      is_small_business
      iban
      bic
      notes
      worker_passport_give_date
      country_id
      country2_id
      dismissal_reason
      start_date
      agent_account_nr
      dismissed_at
      bank_name
      interesent_id
    }
  }
`;

export default getAgentById;
