import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Switch, FormControlLabel } from '@mui/material';
import AlertMessage from '../../AlertMessage';
import setStartDate from './services/setStartDate';
import addServiceToAgentQuery from '../../../Apollo/mutations/addServiceToAgentQuery';
import removeAgentServiceQuery from '../../../Apollo/mutations/removeAgentServiceQuery';

function CustomSwitchComponent({ service, agentServices, agentId }) {
  const [checkedValue, setCheckedValue] = useState(false);
  const [addServiceToAgent, { data, error }] = useMutation(addServiceToAgentQuery);
  const [removeAgentService, { data: removeServiceData, error: removeServiceError }] =
    useMutation(removeAgentServiceQuery);

  useEffect(() => {
    setCheckedValue(agentServices.find((agentService) => agentService.agent_service_service_id === service.id));
  }, [agentServices, service]);

  const handleChange = (event) => {
    if (event.target.checked) {
      const startDate = setStartDate(new Date().toISOString().substring(0, 10));
      const input = {
        id: agentId,
        service_id: service.id,
        price: service.default_price,
        description: service.title,
        type: service.type,
        start_date: startDate,
        end_date: null,
      };
      addServiceToAgent({ variables: { input } }).then((result) => {
        if (result.data) {
          setCheckedValue(true);
        } else {
          setCheckedValue(false);
        }
      });
    } else {
      removeAgentService({ variables: { id: checkedValue.id } }).then((remoteResult) => {
        if (remoteResult.data) {
          setCheckedValue(false);
        } else {
          setCheckedValue(true);
        }
      });
    }
  };

  return (
    <div>
      <FormControlLabel
        value={service.id}
        control={<Switch color="primary" checked={Boolean(checkedValue) || false} onChange={handleChange} />}
        label={service.title}
      />
      <AlertMessage
        typeMessage={(error || removeServiceError) && 'error'}
        alertMessage={(error || removeServiceError) && 'Es ist ein Fehler aufgetreten'}
        isAlertMessage={!!error || !!removeServiceError}
      />
      <AlertMessage
        typeMessage={(data && !error && 'success') || (removeServiceData && !removeServiceError && 'success')}
        alertMessage={
          (data && !error && 'Leistung wurde erfolgreich hinzugefügt') ||
          (removeServiceData && !removeServiceError && 'Leistung wurde erfolgreich gelöscht')
        }
        isAlertMessage={(!!data && !error) || (!!removeServiceData && !removeServiceError)}
      />
    </div>
  );
}

export default CustomSwitchComponent;
