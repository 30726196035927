import { createContext } from 'react';
import { UserContext } from './UserContextValues.types';

export const defaultValues: UserContext = {
  id: 0,
  username: '',
  email: '',
  enabled: false,
  last_login: '',
  roles: '',
  iat: 0,
  exp: 0,
  login: () => Promise.resolve(false),
};

export default createContext<UserContext>(defaultValues);
