import React from 'react';
import ErrorBoundaries from '../../../components/ErrorBoundaries';
import AllLevelsTable from './components/AllLevelsTable';

function RankPage() {
  return (
    <ErrorBoundaries>
      <AllLevelsTable />
    </ErrorBoundaries>
  );
}

export default RankPage;
