// @mui
import { Alert, Tooltip, Stack, Typography, Link, Box } from '@mui/material';
// layouts
import LoginLayout from '../../pages/LoginPage';
//
import AuthLoginForm from './AuthLoginForm';

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <LoginLayout>
      <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
        <Typography variant="h4">Bitte Anmelden</Typography>
      </Stack>

      <AuthLoginForm />
    </LoginLayout>
  );
}
