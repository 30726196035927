import { gql } from '@apollo/client';

const updateUser = gql`
  mutation ($variables: UpdateUser, $username: String!) {
    updateUser(input: $variables, username: $username) {
      username
      email
      enabled
      roles
      last_login
    }
  }
`;

export default updateUser;

