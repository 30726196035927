import MUGLLogo from '../../../img/headerColorLogoMUGL.svg';
import TravelistaLogo from '../../../img/headerColorLogoTRAV.svg';
import AERLogo from '../../../img/headerColorLogoAER.svg';
import TRAVELLogo from '../../../img/headerColorLogoTRAVE.svg';

function logoChooser(office_id: number) {
  const logoList = [MUGLLogo, TravelistaLogo, AERLogo, TRAVELLogo];
  let logo = null;
  switch (office_id) {
    case 1:
      logo = logoList[0];
      break;
    case 2:
      logo = logoList[1];
      break;
    case 3:
      logo = logoList[2];
      break;
    case 4:
      logo = logoList[3];
      break;
    default:
      logo = logoList[4];
      break;
  }
  return logo;
}

export default logoChooser;
