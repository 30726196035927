import React from 'react';
import { Box, Typography, IconButton, Tooltip, useTheme } from '@mui/material';
import Update from '@mui/icons-material/Update';

type Props = {
  title: string;
  date: string;
  status: boolean;
  refreshDate: (title: string) => void;
};

function ItemProject({ title, date, status, refreshDate }: Props) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.grey[700];
  const PRIMARY_DARK = theme.palette.grey[400];
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginLeft: '10px',
        marginRight: '10px',
        marginBottom: '25px',
        paddingRight: '16px',
        paggingLeft: '16px',
      }}
    >
      <Box
        sx={{
          width: '120px',
        }}
      >
        <Typography
          component="h3"
          sx={{
            paddingTop: '3px',
            fontSize: '1.2em',
          }}
        >
          {title} {status}
        </Typography>
        <Typography
          component="span"
          sx={{
            fontSize: '0.8em',
          }}
        >
          {date}
        </Typography>
      </Box>
      <Box
        sx={{
          width: '80px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {!status && (
          <Box
            sx={{
              width: '20px',
              height: '20px',
              background: 'red',
              borderRadius: '50%',
            }}
          ></Box>
        )}
        {status && (
          <Box
            sx={{
              width: '20px',
              height: '20px',
              background: 'green',
              borderRadius: '50%',
            }}
          ></Box>
        )}
        <Tooltip title={'Send Agent to ' + title} arrow>
          <IconButton
            sx={{
              width: '50px',
              height: '50px',
            }}
            onClick={() => {
              refreshDate(title);
            }}
            component="span"
          >
            <Update
              sx={{
                fontSize: '40px',
                color: theme.palette.mode === 'dark' ? PRIMARY_DARK : PRIMARY_LIGHT,
              }}
            />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
}

export default ItemProject;
