import React, { useState, useEffect } from 'react';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import { Alert, AlertProps } from '@mui/material';

interface Props {
  typeMessage: AlertProps['severity'];
  alertMessage?: string;
  isAlertMessage: boolean;
  handleChangeIsAlertMessage?: () => void;
}

function AlertMessage({ typeMessage, alertMessage, isAlertMessage, handleChangeIsAlertMessage }: Props) {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(isAlertMessage);
  }, [isAlertMessage]);

  const handleClose = (event: React.SyntheticEvent | Event, reason: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }
    if (handleChangeIsAlertMessage) {
      handleChangeIsAlertMessage();
    }
    setOpen(false);
  };
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert
        onClose={() => {
          setOpen(false);
        }}
        severity={typeMessage}
      >
        {alertMessage || 'Message'}
      </Alert>
    </Snackbar>
  );
}

export default AlertMessage;
