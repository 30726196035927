import { gql } from '@apollo/client';

const updateAgentServiceQuery = gql`
  mutation ($variables: AgentServiceUpdate) {
    updateAgentService(input: $variables)
  }
`;

export default updateAgentServiceQuery;

