import { BranchOffice } from './BranchOffice.types';
import { ContractType } from './ContractType.types';
import { Country } from './Country.types';
import { Level } from './Level.types';
import { Rank } from './Rank.types';
import { Status } from './Status.types';

export interface Agent {
  id: string;
  branch_office: BranchOffice;
  rank: Rank;
  status: Status;
  manager: Agent | null;
  level: Level;
  contract_type: ContractType;
  name: string;
  surname: string;
  birthday: string;
  is_male: number;
  photo: string | null;
  thumbnail: string;
  city: string;
  address: string;
  zip: string;
  city2: string | null;
  address2: string | null;
  zip2: string | null;
  phone: string | null;
  mobile: string;
  fax: string | null;
  email_corp: string;
  email_private: string;
  agent_id: number;
  jackplus_id: number;
  business_owner: string | null;
  business_registration_date: string;
  tax_nr: string;
  ust_nr: string;
  is_small_business: number;
  iban: string;
  bic: string;
  notes: string;
  worker_passport_give_date: string | null;
  country: Country;
  country2: Country;
  dismissal_reason: string | null;
  start_date: string;
  agent_account_nr: number;
  dismissed_at: string | null;
  reverse_charge: number | null;
  branch_office_email_postfix: string;
  lat: string | null;
  lon: string | null;
  bank_name: string | null;
}

export enum AgentStatus {
  inProcess = 9,
  active = 3,
  restingCooperation = 4,
  acquired = 6,
  interest = 8,
  dismissed = 5,
  failed = 10,
}
