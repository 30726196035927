import { Agent } from '../Agent.types';

const InitDate = new Date('01.01.1990').toISOString();

const AgentInit: Agent = {
  id: '',
  branch_office: {
    domain: '',
    email_postfix: '',
    id: 0,
    is_default: false,
    logo: '',
    max_agent_id: 0,
    min_agent_id: 0,
    show_in_export_xml: 0,
    title: '',
    title_for_worker_passport: '',
  },
  rank: {
    id: 0,
    title: '',
    is_manager: 0,
    is_default: false,
  },
  status: {
    id: 0,
    title: '',
    is_default: false,
  },
  manager: null,
  level: {
    id: 0,
    title: '',
    is_default: false,
  },
  contract_type: {
    id: 0,
    title: '',
    is_default: false,
  },
  name: '',
  surname: '',
  birthday: InitDate,
  is_male: 0,
  photo: '',
  city: '',
  address: '',
  zip: '',
  city2: '',
  address2: '',
  zip2: '',
  phone: '',
  mobile: '',
  fax: '',
  email_corp: '',
  email_private: '',
  agent_id: 0,
  jackplus_id: 0,
  business_owner: '',
  business_registration_date: '',
  tax_nr: '',
  ust_nr: '',
  is_small_business: 0,
  iban: '',
  bic: '',
  notes: '',
  worker_passport_give_date: InitDate,
  country: {
    id: 0,
    title: '',
    is_default: false,
  },
  country2: {
    id: 0,
    title: '',
    is_default: false,
  },
  dismissal_reason: '',
  start_date: InitDate,
  agent_account_nr: 0,
  dismissed_at: '',
  thumbnail: '',
  branch_office_email_postfix: '',
  reverse_charge: 0,
  lat: null,
  lon: null,
  bank_name: null,
};

export default AgentInit;
