import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  services: Yup.array().of(
    Yup.object().shape({
      description: Yup.string().when('name', (name, schema) => {
        switch (name) {
          case 'Domain':
            return schema
              .max(50, 'Maximal 50 Zeichen erlaubt')
              .matches(
                /^(?=.{1,50}$)([a-zA-Z0-9]+([-_]?[a-zA-Z0-9]+)*\.)+[a-zA-Z]{2,}$/,
                'Ungültige Domain'
              );
          case 'myJACK Debitor':
            return schema
              .matches(/^[A-Za-z]\d{6}$/, 'Ein Buchstabe und 6 Zahlen erforderlich');
          case 'myJACK CRS Nummer':
            return schema.matches(/^\d{9}$/, '9 Zahlen erforderlich');
          case 'myJACK Benutzername':
            return schema
              .matches(/^[a-zA-Z]{9}\d$/, '9 Buchstaben und 1 Zahl erforderlich');
          case 'Bistro Portal Benutzername':
            return schema
              .matches(/^[a-zA-Z]{3}\d{6}$/, '3 Buchstaben und 6 Zahlen erforderlich');
          case 'Passolution Benutzer':
            return schema
              .max(35, 'Maximal 35 Zeichen erlaubt')
              .matches(
                /^[a-zA-Z0-9\s!@#$%^&*()_+\-=[\]{}|\\:;"'<>,.?/]*$/,
                'Nur Buchstaben, Zahlen und Sonderzeichen erlaubt',
              );
          case 'Print Planet Benutzer':
            return schema.max(30, 'Maximal 30 Zeichen erlaubt');
          case 'MSC Book Benutzer':
            return schema.max(20, 'Maximal 20 Zeichen erlaubt');
          case 'AIDA Benutzer':
            return schema.max(35, 'Maximal 35 Zeichen erlaubt');
          case 'HMR Benutzer':
            return schema
              .max(30, 'Maximal 30 Zeichen erlaubt')
              .matches(
                /^[a-zA-Z0-9\s!@#$%^&*()_+\-=[\]{}|\\:;"'<>,.?/]*\d{7}$/,
                'Buchstaben, Sonderzeichen, 7 Zahlen erforderlich',
              );
            case 'Bemerkung (PaxLounge)':
            return schema
              .max(50, 'Maximal 50 Zeichen erlaubt')
              .matches(
                /^[a-zA-Z0-9\s!@#$%^&*()_+\-=[\]{}|\\:;"'<>,.?/]*$/,
                'Nur Buchstaben, Zahlen und Sonderzeichen erlaubt',
              );
              case 'Bemerkung (Cruise Compass)':
            return schema
              .max(50, 'Maximal 50 Zeichen erlaubt')
              .matches(
                /^[a-zA-Z0-9\s!@#$%^&*()_+\-=[\]{}|\\:;"'<>,.?/]*$/,
                'Nur Buchstaben, Zahlen und Sonderzeichen erlaubt',
              );
              case 'Bemerkung (Amadeus)':
            return schema
              .max(50, 'Maximal 50 Zeichen erlaubt')
              .matches(
                /^[a-zA-Z0-9\s!@#$%^&*()_+\-=[\]{}|\\:;"'<>,.?/]*$/,
                'Nur Buchstaben, Zahlen und Sonderzeichen erlaubt',
              );
          default:
            return schema;
        }
      }),
    }),
  ),
});
export default validationSchema;
