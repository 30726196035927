import React from 'react';
import { useQuery } from '@apollo/client';
import getClosingDataPayrollPeriod from '../../../../../Apollo/queries/getClosingDataPayrollPeriod';
import { Box, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';

function lastPaylaodMonth(dateOfLastPayload: string) {
  if (!dateOfLastPayload) {
    const startDate = '2011-03-05T14:48:00.000Z'.split('-');
    const splitStartDateT = startDate[2].split('T');
    const newDate = new Date(parseInt(startDate[0], 10), parseInt(startDate[1], 10), parseInt(splitStartDateT[0], 10));
    const lastPayloadMonth = newDate.getMonth() - 1;
    const actualDate = new Date();
    let minDate = null;
    if (actualDate.getMonth() > lastPayloadMonth) {
      minDate = new Date(actualDate.getFullYear(), actualDate.getMonth() - 1, 1);
    } else {
      minDate = new Date(actualDate.getFullYear(), actualDate.getMonth(), 1);
    }
    return minDate;
  }
  const startDate = dateOfLastPayload.split('-');
  const splitStartDateT = startDate[2].split('T');
  const newDate = new Date(parseInt(startDate[0], 10), parseInt(startDate[1], 10), parseInt(splitStartDateT[0], 10));
  const lastPayloadMonth = newDate.getMonth() - 1;
  const actualDate = new Date();
  let minDate = null;
  if (actualDate.getMonth() > lastPayloadMonth) {
    minDate = new Date(actualDate.getFullYear(), actualDate.getMonth() - 1, 1);
  } else {
    minDate = new Date(actualDate.getFullYear(), actualDate.getMonth(), 1);
  }
  return minDate;
}

interface Props {
  value: string | undefined;
  onValueChange: (value: string) => void;
}

function isInCentralEuropeanNormalTime(date: Date): boolean {
  const timezoneOffset = date.getTimezoneOffset();
  return timezoneOffset === -60;
}

function isInCentralEuropeanSummerTime(date: Date): boolean {
  const timezoneOffset = date.getTimezoneOffset();
  return timezoneOffset === -120;
}

function createDateFromMonthYear(dateString: string): Date {
  const [year, month] = dateString.split('-');
  const date = new Date(parseInt(year), parseInt(month) - 1);
  return date;
}

function DateEditor({ value, onValueChange }: Props): JSX.Element {
  const { data: dateOfLastPayload } = useQuery(getClosingDataPayrollPeriod);
  return (
    <DesktopDatePicker
      views={['year', 'month']}
      renderInput={(params) => <TextField {...params} />}
      minDate={lastPaylaodMonth(dateOfLastPayload?.getClosingDataPayrollPeriod)}
      value={value || ''}
      onChange={(date) => {
        if (date) {
          let currentDateInNormalTime;
          let currentDateInSummerTime;
          const newDateValueInNormalTime = isInCentralEuropeanNormalTime(date);
          const newDateValueInSummerTime = isInCentralEuropeanSummerTime(date);
          if (value) {
            currentDateInNormalTime = isInCentralEuropeanNormalTime(createDateFromMonthYear(value));
            currentDateInSummerTime = isInCentralEuropeanSummerTime(createDateFromMonthYear(value));
          }
          if (
            (currentDateInNormalTime && newDateValueInNormalTime) ||
            (currentDateInSummerTime && newDateValueInSummerTime)
          ) {
            const arr = date?.toISOString().split('T').toString().split('-');
            if (arr) {
              return onValueChange(`${arr[0]}-${arr[1]}`);
            }
          }
          if (currentDateInNormalTime && newDateValueInSummerTime) {
            const arr = date?.toISOString().split('T').toString().split('-');
            if (arr) {
              const month = parseInt(arr[1]) + 1;
              const jahr = arr[0];
              return onValueChange(`${jahr}-0${month}`);
            }
          }
          if (currentDateInSummerTime && newDateValueInNormalTime) {
            const arr = date?.toISOString().split('T').toString().split('-');
            if (arr) {
              const month = parseInt(arr[1]);
              const jahr = arr[0];
              return onValueChange(month >= 10 ? `${jahr}-${month}` : `${jahr}-0${month}`);
            }
          }
          const arr = date?.toISOString().split('T').toString().split('-');
          const month = parseInt(arr[1]);
          const jahr = arr[0];
          return onValueChange(month >= 10 ? `${jahr}-${month}` : `${jahr}-0${month}`);
        }
        return null;
      }}
    />
  );
}

export default DateEditor;
