import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

interface Props {
  onExecute: () => void;
}

function EditButton({ onExecute }: Props) {
  return (
    <IconButton onClick={onExecute} title="Edit row">
      <EditIcon />
    </IconButton>
  );
}
export default EditButton;
