import { gql } from '@apollo/client';

export default gql`
  query RanksQuery {
    getAllRanks {
      title
      id
      is_manager
      is_default
    }
  }
`;
