import * as Yup from 'yup';
import { Agent } from '../Agent.types';

export default function ChangeCorpEmail(agentsCorpEmailList: Agent[], currentCorpEmail: string | null) {
  return Yup.object().shape({
    email_corp: Yup.string()
      .max(128, 'max 128 Zeichen')
      .required('Pflichtfeld')
      .email('Ungültige Email')
      .test('uniq', 'Email ist bereits vorhanden', (value) => {
        if (currentCorpEmail === value) {
          return true;
        }
        const corpEmailSearch = agentsCorpEmailList.filter((agent) => agent.email_corp === `${value}`);
        if (corpEmailSearch.length > 0) {
          return false;
        }
        return true;
      })
      .test('uniq', 'keine Umlaute', (value) => {
        if (value) {
          if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            return true;
          }
        }
        return false;
      }),
  });
}
