import { gql } from '@apollo/client';

const getAllBranchofficeQuery = gql`
  query {
    getAllBranchOffice {
      id
      title
      domain
      email_postfix
      show_in_export_xml
      logo
      title_for_worker_passport
      min_agent_id
      max_agent_id
      is_default
      zip
      city
      address
      phone
      fax
      email
    }
  }
`;
export default getAllBranchofficeQuery;
