import React, { useContext, useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { BookingAccount } from '../../../../../types/BookingAccount.types';
import DataContext from '../../../../../context/DataProvider/DataContext';

function numberIsIdentical(
  inputValue: DataTypeProvider.ValueEditorProps['value'],
  currentValue: string | undefined,
  listOfBookingAccounts: BookingAccount[],
): boolean {
  let numberIsIdentical = false;

  if (currentValue) {
    if (inputValue?.toString() === currentValue) {
      numberIsIdentical = false;
    } else {
      numberIsIdentical = true;
    }
  } else {
    for (let index = 0; index < listOfBookingAccounts.length; index++) {
      if (inputValue === listOfBookingAccounts[index].booking_account_nr.toString()) {
        numberIsIdentical = true;
      }
    }
  }

  return numberIsIdentical;
}

function isNumberWith10Digits(num: string) {
  const numStr = num.toString();
  if (numStr.length >= 10) {
    if (/^\d+$/.test(numStr)) {
      return true;
    }
  }

  return false;
}

function validateValue(
  value: DataTypeProvider.ValueEditorProps['value'],
  initialValue: string | undefined,
  listOfBookingAccounts: BookingAccount[],
): { error: boolean; helperText: string } {
  let nameIsIdentical: boolean = numberIsIdentical(value, initialValue, listOfBookingAccounts);
  if (!value) {
    return {
      error: true,
      helperText: 'Pflichtfeld',
    };
  } else if (isNumberWith10Digits(value)) {
    return {
      error: true,
      helperText: 'Max 10 Zeichen lang',
    };
  } else if (nameIsIdentical) {
    return {
      error: true,
      helperText: 'Buchungskontonummer bereits vorhanden',
    };
  } else {
    return {
      error: false,
      helperText: '',
    };
  }
}

function NumberEditor({ value, onValueChange, disabled }: DataTypeProvider.ValueEditorProps) {
  const { bookingAccount } = useContext(DataContext);
  const [helperText, setHelperText] = useState('');
  const [error, setError] = useState(false);

  const { current: initialValue } = useRef(value);

  useEffect(() => {
    const valid = validateValue(value, initialValue, bookingAccount);
    setError(valid.error);
    setHelperText(valid.helperText);
  }, [value]);

  return (
    <Box>
      <TextField
        disabled={disabled}
        value={value || ''}
        type="number"
        onChange={(event) => onValueChange(event.target.value)}
        error={error}
        helperText={disabled ? '' : helperText}
      />
    </Box>
  );
}

export default NumberEditor;
