import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, IconButton, Tooltip, Stack } from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { Receipt } from 'global-intern-types';
import { Formik } from 'formik';
import createReceiptQuery from '../../../../Apollo/mutations/createReceipt';
import ReceiptEditAgent from './ReceiptEditAgent';
import ReceiptEditBookingAccount from './ReceiptEditBookingAccount';
import ReceiptEditDescription from './ReceiptEditDescription';
import ReceiptEditMonth from './ReceiptEditMonth';
import ReceiptEditPrice from './ReceiptEditPrice';
import ReceiptEditTax from './ReceiptEditTax';
import ReceiptEditType from './ReceiptEditType';
import ReceiptEditYear from './ReceiptEditYear';
import AllReceiptsTable from '../../AllReceiptsPage/components/AllReceiptsTable';
import DataContext from '../../../../context/DataProvider/DataContext';
import { Agent, AgentStatus } from '../../../../types/Agent.types';
import UserContext from '../../../../context/UserProvider/UserContext';
import ReceiptInput from '../../../../types/ReceiptInput.types';
import getClosingDataPayrollPeriod from '../../../../Apollo/queries/getClosingDataPayrollPeriod';
import ReceiptEditValidationSchema from '../validation/receiptEdit.valid';
import { CONTRACTTYPSLIST } from '../../../../services/config/CONST';
import { useSnackbar } from '../../../../components/snackbar';

function ReceiptEdit() {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedAgentId, setSelectedAgentId] = useState<string>();
  const user = useContext(UserContext);
  const { agents, receipt: contextReceipts, loaderFun } = useContext(DataContext);
  const [createReceipt, { loading, error }] = useMutation<{ createReceipt: Receipt }>(createReceiptQuery);
  const { data: PeriodData } = useQuery(getClosingDataPayrollPeriod);
  const [allAgents, setAllAgents] = useState<Agent[]>([]);

  useEffect(() => {
    const closingDatePayrollPeriod = new Date(PeriodData?.getClosingDataPayrollPeriod);
    const closingPeriodDateFirstDay = new Date(
      closingDatePayrollPeriod.getFullYear(),
      closingDatePayrollPeriod.getMonth(),
      1,
    );

    setAllAgents(
      agents.filter((agent: Agent) => {
        const dismissedDate = agent.dismissed_at ? new Date(agent.dismissed_at) : null;
        const dismissedDateMonth = dismissedDate?.getMonth();
        const dismissedDateYear = dismissedDate?.getFullYear();
        const dismissedDateFirstDay =
          dismissedDateYear && dismissedDateMonth ? new Date(dismissedDateYear, dismissedDateMonth, 1) : null;

        const date = new Date();
        const dateLastMonthFirstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);

        if (
          agent.status.id === AgentStatus.active ||
          (dismissedDateFirstDay === dateLastMonthFirstDay && closingPeriodDateFirstDay === dateLastMonthFirstDay)
        ) {
          return agent;
        }
        return null;
      }),
    );
  }, [agents]);

  const defaultValues: ReceiptInput = {
    type: CONTRACTTYPSLIST[0].value,
    month: new Date().getMonth() + 1,
    author_id: user.id,
    year: new Date().getUTCFullYear(),
    tax_id: 2,
  };

  return (
    <Stack paddingTop={2} direction={'row'} spacing={2} overflow={'auto'}>
      <Formik
        enableReinitialize
        isInitialValid
        initialValues={defaultValues}
        validationSchema={ReceiptEditValidationSchema}
        onSubmit={(values, { resetForm }) => {
          createReceipt({ variables: { input: { ...values } } }).then((res) => {
            if (res?.data?.createReceipt) {
              const updatedReceipts = [...contextReceipts, res.data.createReceipt];
              loaderFun.changeReceipts(updatedReceipts);
            }
            if (error) {
              enqueueSnackbar(`Fehler beim Erstellen des Belegs:`, { variant: 'error' });
              console.error('Error-ReceiptEdit:', error.message);
            } else {
              enqueueSnackbar('Beleg erfolgreich erstellt', { variant: 'success' });
            }
          });
        }}
      >
        {({ submitForm, values, errors, resetForm, isValid, dirty }) => {
          return (
            <Stack spacing={2} minWidth={500}>
              <ReceiptEditAgent
                allAgents={allAgents}
                onChange={(agent_id) => {
                  setSelectedAgentId(agent_id);
                }}
              />
              <ReceiptEditType />
              <ReceiptEditBookingAccount />
              <ReceiptEditPrice />
              <ReceiptEditDescription />
              <ReceiptEditTax />
              <ReceiptEditMonth />
              <ReceiptEditYear />
              <Box ml={1}>
                <Button onClick={submitForm} disabled={!dirty || !isValid} variant="contained">
                  Beleg erstellen
                </Button>
                <Tooltip title="Zurücksetzten">
                  <IconButton
                    onClick={() => {
                      resetForm();
                    }}
                  >
                    <RotateLeftIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Stack>
          );
        }}
      </Formik>
      <Stack maxWidth={'100%'}>
        <AllReceiptsTable agentId={selectedAgentId} />
      </Stack>
    </Stack>
  );
}

export default ReceiptEdit;
