import React, { useContext } from 'react';
import DataContext from '../../../context/DataProvider/DataContext';
import { Grid, MenuItem, Select, FormControl, FormHelperText, InputLabel, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { AgentInputType } from '../../../types/AgentInputType.types';
import DebounceTextField from '../../../components/DebounceTextfield';
import { AgentStatus } from '../../../types/Agent.types';
import Agentv2 from '../../../types/Agentv2.types';

function AgentFormBillingAddress() {
  const { country } = useContext(DataContext);
  const { values, handleChange, errors, handleBlur, initialValues } = useFormikContext<AgentInputType | Agentv2>();

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h5">abweichende Rechnungsadresse</Typography>
      </Grid>
      <Grid item>
        <DebounceTextField
          label="Straße"
          name="address2"
          value={values.address2 || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={errors.address2}
          error={Boolean(errors.address2) || false}
          fullWidth
          disabled={initialValues.status_id === AgentStatus.dismissed || values.status_id === AgentStatus.failed}
        />
      </Grid>
      <Grid item>
        <DebounceTextField
          label="PLZ"
          name="zip2"
          value={values.zip2 || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={errors.zip2}
          error={Boolean(errors.zip2) || false}
          fullWidth
          disabled={initialValues.status_id === AgentStatus.dismissed || values.status_id === AgentStatus.failed}
        />
      </Grid>
      <Grid item>
        <DebounceTextField
          label="Ort"
          name="city2"
          value={values.city2 || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={errors.city2}
          error={Boolean(errors.city2) || false}
          fullWidth
          disabled={initialValues.status_id === AgentStatus.dismissed || values.status_id === AgentStatus.failed}
        />
      </Grid>
      <Grid item>
        <FormControl
          fullWidth
          error={Boolean(errors.country2_id) || false}
          disabled={initialValues.status_id === AgentStatus.dismissed || values.status_id === AgentStatus.failed}
        >
          <InputLabel>Land</InputLabel>
          <Select label="Land" name="country2_id" value={values.country2_id || ''} onChange={handleChange}>
            {country?.map((item, index) => {
              return (
                <MenuItem key={index} value={item.id}>
                  {item.title}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>{errors.country2_id}</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default AgentFormBillingAddress;
