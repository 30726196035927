import React, { useContext } from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { useMutation } from '@apollo/client';
import createRankQuery from '../../../../Apollo/mutations/createRank';
import updateRankQuery from '../../../../Apollo/mutations/updateRank';
import BooleanFormatter from './services/booleanFormater';
import BooleanEditor from './components/BooleanEditor';
import TextEditor from './components/TextEditor';
import commitChanges from './commitChanges';
import Table from '../../../../components/Table/Table';
import DataContext from '../../../../context/DataProvider/DataContext';
import columns from './columnDefenition';

const booleanColumns = ['is_default', 'is_manager'];

function BooleanTypeProvider() {
  return (
    <DataTypeProvider formatterComponent={BooleanFormatter} editorComponent={BooleanEditor} for={booleanColumns} />
  );
}

const textColumns = ['title'];

function TextTypeProvider() {
  return <DataTypeProvider editorComponent={TextEditor} for={textColumns} />;
}

const Provider = [BooleanTypeProvider, TextTypeProvider];

function AllRanksTable() {
  const { rank, loaderFun } = useContext(DataContext);
  const [createRank] = useMutation(createRankQuery);
  const [updateRank] = useMutation(updateRankQuery);

  const editingStateColumnExtensions = [{ columnName: 'id', editingEnabled: false }];
  return (
    <Table
      row={rank}
      columns={columns}
      onCommitChanges={({ added, changed }) =>
        commitChanges({ added, changed, rows: rank, loaderFun, createRank, updateRank })
      }
      columnExtensions={editingStateColumnExtensions}
      dataTypeProvider={Provider}
      defaultColumnWidths={columns.map((column) => ({ columnName: column.name, width: 'auto' }))}
      editable
      showAddCommand
      showEditCommand
    />
  );
}

export default AllRanksTable;
