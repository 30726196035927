import React, { useContext } from 'react';
import { MenuItem, Select, FormControl, FormHelperText, InputLabel, Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import DataContext from '../../../context/DataProvider/DataContext';
import SexInitValue from '../../../types/initValues/Sex.init';
import { AgentInputType } from '../../../types/AgentInputType.types';
import DebounceTextField from '../../../components/DebounceTextfield';
import { AgentStatus } from '../../../types/Agent.types';
import Agentv2 from '../../../types/Agentv2.types';
import { Status } from '../../../types/Status.types';

/**
 *
 * @param statusArray vollständige Array von Statis
 * @returns manipulierte Array von Statis, ohne AgentStatus.acquired, AgentStatus.restingCooperation, AgentStatus.interest
 */

function excludeStatus(statusArray: Status[]): Status[] {
  const statusToExclude = [AgentStatus.acquired, AgentStatus.restingCooperation, AgentStatus.interest];
  let filteredArray: Status[] = [];
  if (statusArray) {
    filteredArray = statusArray.filter((status) => !statusToExclude.includes(status.id));
  }
  return filteredArray;
}

function AgentFormPersonalData() {
  const { status } = useContext(DataContext);
  const { values, handleChange, errors, handleBlur, initialValues } = useFormikContext<AgentInputType | Agentv2>();

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <DebounceTextField
          data-testid="agent-personaldata-name"
          type="text"
          label="Vorname"
          aria-label="name"
          name="name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={errors.name}
          error={Boolean(errors.name) || false}
          fullWidth
          disabled={initialValues.status_id === AgentStatus.dismissed || values.status_id === AgentStatus.failed}
        />
      </Grid>
      <Grid item>
        <DebounceTextField
          data-testid="agent-personaldata-surname"
          label="Name"
          name="surname"
          aria-label="surname"
          value={values.surname}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={errors.surname}
          error={Boolean(errors.surname) || false}
          fullWidth
          disabled={initialValues.status_id === AgentStatus.dismissed || values.status_id === AgentStatus.failed}
        />
      </Grid>
      <Grid item>
        <DebounceTextField
          label="abweichender Vertragsinhaber"
          name="business_owner"
          value={values.business_owner || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={errors.business_owner}
          error={Boolean(errors.business_owner) || false}
          fullWidth
          disabled={initialValues.status_id === AgentStatus.dismissed || values.status_id === AgentStatus.failed}
        />
      </Grid>
      <Grid item>
        <DebounceTextField
          label="Berater Nummer"
          aria-label="agent_id"
          name="agent_id"
          value={values.agent_id || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={errors.agent_id}
          error={Boolean(errors.agent_id) || false}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item>
        <DebounceTextField
          type="date"
          label="Geburtstag"
          name="birthday"
          value={values.birthday}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={errors.birthday}
          error={Boolean(errors.birthday) || false}
          disabled={initialValues.status_id === AgentStatus.dismissed || values.status_id === AgentStatus.failed}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item>
        <FormControl
          disabled={
            values.agent_id === null ||
            initialValues.status_id !== AgentStatus.inProcess ||
            (values.status_id === AgentStatus.failed && values.agent_id !== 0)
          }
          fullWidth
          error={Boolean(errors.status_id) || false}
        >
          <InputLabel>Status</InputLabel>
          <Select name="status_id" value={values.status_id || ''} onChange={handleChange}>
            {excludeStatus(status)
              ?.filter((item) => {
                if (values.status_id !== AgentStatus.dismissed) {
                  return item.id !== AgentStatus.dismissed;
                }
                return item;
              })
              .map((item, index) => {
                return (
                  <MenuItem key={index} value={item.id}>
                    {item.title}
                  </MenuItem>
                );
              })}
          </Select>
          <FormHelperText>{errors.status_id}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl
          fullWidth
          error={Boolean(errors.is_male) || false}
          disabled={initialValues.status_id === AgentStatus.dismissed || values.status_id === AgentStatus.failed}
        >
          <InputLabel>Geschlecht</InputLabel>
          <Select name="is_male" value={values.is_male} onChange={handleChange}>
            {SexInitValue.map((item, index) => {
              return (
                <MenuItem key={index} value={item.id}>
                  {item.title}
                </MenuItem>
              );
            })}
          </Select>

          <FormHelperText>{errors.is_male}</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
}
export default AgentFormPersonalData;
