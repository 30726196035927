import { gql } from '@apollo/client';

const getServicesByAgentID = gql`
  query ($id: String!) {
    getServicesByAgentID(id: $id) {
      id
      start_date
      end_date
      agent_service {
        agent_id
        service_id
        price
        description
        customer_id
        type
        service {
          id
          default_price
          title
          booking_account {
            id
            title
            booking_account_nr
            external_reference
          }
          type
          tax {
            id
            code
            percent
          }
          fixed_start_month
        }
      }
    }
  }
`;

export default getServicesByAgentID;
