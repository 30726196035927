import React from 'react';
import { Grid, MenuItem, Select, FormControl, FormHelperText, InputLabel } from '@mui/material';
import { useFormikContext } from 'formik';
import SmallBusinessInit from '../../../types/initValues/SmallBusiness.init';
import { AgentInputType } from '../../../types/AgentInputType.types';
import DebounceTextField from '../../../components/DebounceTextfield';
import InfoButton from '../../../components/InfoButton';
import { AgentStatus } from '../../../types/Agent.types';
import Agentv2 from '../../../types/Agentv2.types';

const ibanInfoText =
  'Die IBAN setzt sich folgendermaßen zusammen: 2-stelliger Ländercode + 2-stellige Prüfziffern + Maximal 30-stellige Kontoidentifikation (bestehend aus den Buchstaben A–Z oder Ziffern). Die IBAN-Länge für Deutschland beträgt: 22 Zeichen. Die IBAN-Länge für Österreich beträgt: 20 Zeichen. Die IBAN-Länge für Italien beträgt: 27 Zeichen. Die IBAN-Länge für Spanien beträgt: 24 Zeichen. ';

function AgentFormBusinessInfo() {
  const { values, handleChange, errors, handleBlur, initialValues } = useFormikContext<AgentInputType | Agentv2>();

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <DebounceTextField
          label="Steuernummer"
          name="tax_nr"
          aria-label="tax_nr"
          value={values.tax_nr || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={errors.tax_nr}
          error={Boolean(errors.tax_nr) || false}
          fullWidth
          disabled={initialValues.status_id === AgentStatus.dismissed || values.status_id === AgentStatus.failed}
        />
      </Grid>
      <Grid item>
        <DebounceTextField
          label="Umsatzsteuer ID"
          name="ust_nr"
          aria-label="ust_nr"
          value={values.ust_nr || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={errors.ust_nr}
          error={Boolean(errors.ust_nr) || false}
          fullWidth
          disabled={initialValues.status_id === AgentStatus.dismissed || values.status_id === AgentStatus.failed}
        />
      </Grid>
      <Grid item>
        <FormControl
          fullWidth
          error={Boolean(errors.is_small_business) || false}
          disabled={initialValues.status_id === AgentStatus.dismissed || values.status_id === AgentStatus.failed}
        >
          <InputLabel>Besteuerungsart</InputLabel>
          <Select
            label="Besteuerungsart"
            name="is_small_business"
            value={values.is_small_business}
            onChange={handleChange}
          >
            {SmallBusinessInit?.map((item) => (
              <MenuItem key={Math.random()} value={item.id}>
                {item.title}
              </MenuItem>
            ))}
          </Select>

          <FormHelperText>{errors.is_small_business}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item>
        <DebounceTextField
          label="IBAN"
          name="iban"
          aria-label="iban"
          value={values.iban || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={errors.iban}
          error={Boolean(errors.iban) || false}
          fullWidth
          disabled={initialValues.status_id === AgentStatus.dismissed || values.status_id === AgentStatus.failed}
          InputProps={{
            endAdornment: <InfoButton tooltiptext={ibanInfoText} />,
          }}
        />
      </Grid>
      <Grid item>
        <DebounceTextField
          label="Bankname"
          name="bank_name"
          aria-label="bank_name"
          value={values.bank_name || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={errors.bank_name}
          error={Boolean(errors.bank_name) || false}
          fullWidth
          disabled={initialValues.status_id === AgentStatus.dismissed || values.status_id === AgentStatus.failed}
        />
      </Grid>
      <Grid item>
        <DebounceTextField
          label="BIC"
          name="bic"
          aria-label="bic"
          value={values.bic || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={errors.bic}
          error={Boolean(errors.bic) || false}
          fullWidth
          disabled={initialValues.status_id === AgentStatus.dismissed || values.status_id === AgentStatus.failed}
        />
      </Grid>
      <Grid item>
        <DebounceTextField
          type="date"
          label="Start Date"
          name="start_date"
          aria-label="start_date"
          value={values.start_date || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={errors.start_date}
          error={Boolean(errors.start_date) || false}
          fullWidth
          disabled={initialValues.status_id === AgentStatus.dismissed || values.status_id === AgentStatus.failed}
          inputProps={{
            max: '2100-12-31',
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    </Grid>
  );
}

export default AgentFormBusinessInfo;
