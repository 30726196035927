import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';

interface ServiceSelect {
  booking_account: string;
  tax: string;
  fixed_start_month: string | undefined | number | null;
  default_price: number;
  id: number;
  title: string;
  type: string;
}

type Props = {
  service: ServiceSelect;
  servicesList: ServiceSelect[];
  changeFunc: any;
};

function ServicesSelect({ service, servicesList, changeFunc }: Props) {
  return (
    <FormControl variant="filled" required fullWidth>
      <InputLabel id="ReceiptEditTaxLabel">Leistungen</InputLabel>
      <Select
        name="id"
        labelId="ServicesSelect"
        id="ServicesSelect"
        value={service.id || ''}
        onChange={changeFunc}
        sx={{
          minWidth: 185,
        }}
      >
        {servicesList.map((item: ServiceSelect) => (
          <MenuItem key={item.id} value={item.id}>
            {item.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default ServicesSelect;
