import { gql } from '@apollo/client';

const createLevel = gql`
  mutation ($variables: LevelInput!) {
    createLevel(input: $variables) {
      id
      title
      is_default
    }
  }
`;
export default createLevel;
