import { gql } from '@apollo/client';

const updateBranchOffice = gql`
  mutation ($variables: BranchofficeUpdate!, $id: Int!) {
    updateBranchOffice(input: $variables, office_id: $id) {
      id
      title
      domain
      email_postfix
      show_in_export_xml
      logo
      title_for_worker_passport
      min_agent_id
      max_agent_id
      is_default
      phone
      zip
      address
      email
      fax
      city
    }
  }
`;
export default updateBranchOffice;
