import * as Yup from 'yup';

const phoneRegExpPhone = /^(\+)?(?:[0-9] ?){6,14}[0-9]$/;
const phoneRegExpMobile = /^(\+?\d{1,3}[- ]?)?\d{3,5}([ -]?\d{5,7})$/;

const interssentValidationSchema = Yup.object().shape({
  name: Yup.string().required('Pflichtfeld'),
  surname: Yup.string().required('Pflichtfeld'),
  agent_id: Yup.number().integer('Keine Kommazahlen').max(9999, 'max 4 Zeichen').nullable(),
  birthday: Yup.string().nullable(),
  is_male: Yup.number().required('Pflichtfeld').integer('Keine Kommazahlen').max(1).nullable(),
  branch_office_id: Yup.number().integer('Keine Kommazahlen').max(10).nullable(),
  contract_type_id: Yup.number()
    .when('agent_id', {
      is: (value: any) => value != null,
      then: Yup.number().max(10).required('Pflichtfeld').typeError('Pflichtfeld'),
    })
    .nullable(),
  email_private: Yup.string()
    .max(128, 'max 128 Zeichen')
    .required('Pflichtfeld')
    .test('uniq', 'Validierung fehlgeschlagen siehe Info', (value) => {
      if (value) {
        if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value)) {
          return true;
        }
      }
      return false;
    }),
  notes: Yup.string().nullable(),
  city: Yup.string()
    .max(128, 'max. 128 Zeichen')
    .when('agent_id', {
      is: (value: any) => value != null,
      then: Yup.string().max(128, 'max. 128 Zeichen').required('Pflichtfeld').typeError('Pflichtfeld'),
    })
    .nullable(),
  address: Yup.string()
    .max(128, 'max. 128 Zeichen')
    .when('agent_id', {
      is: (value: any) => value != null,
      then: Yup.string().max(128, 'max. 128 Zeichen').required('Pflichtfeld').typeError('Pflichtfeld'),
    })
    .nullable(),
  zip: Yup.string()
    .matches(/^[0-9]+$/, 'nur Zahlen')
    .min(1, 'min 1 Zeichen')
    .max(9, 'max 9 Zeichen')
    .when('agent_id', {
      is: (value: any) => value != null,
      then: Yup.string()
        .matches(/^[0-9]+$/, 'nur Zahlen')
        .min(1, 'min 1 Zeichen')
        .max(9, 'max 9 Zeichen')
        .required('Pflichtfeld')
        .typeError('Pflichtfeld'),
    })
    .nullable(),
  country_id: Yup.number()
    .integer('Keine Kommazahlen')
    .max(10)
    .when('agent_id', {
      is: (value: any) => value != null,
      then: Yup.number().integer('Keine Kommazahlen').max(10).required('Pflichtfeld').typeError('Pflichtfeld'),
    })
    .nullable(),
  phone: Yup.string().matches(phoneRegExpPhone, 'Ungültige Telefonnummer').nullable(),
  mobile: Yup.string().matches(phoneRegExpMobile, 'Ungültige Telefonnummer').nullable(),
});

export default interssentValidationSchema;
