import React from 'react';
import { useFormikContext } from 'formik';
import { Autocomplete, TextField } from '@mui/material';
import { CONTRACTTYPSLIST } from '../../../../services/config/CONST';
import ReceiptInput from '../../../../types/ReceiptInput.types';

function ReceiptEditType() {
  const { setFieldValue, handleBlur, values, errors, touched } = useFormikContext<ReceiptInput>();

  return (
    <Autocomplete
      id="ReceiptEditTypeLabel"
      fullWidth
      options={CONTRACTTYPSLIST}
      getOptionLabel={(contractType) => `${contractType.title}`}
      onChange={(e, value) => {
        setFieldValue('type', value?.value || '');
      }}
      value={CONTRACTTYPSLIST.find((item) => item.value === values.type)}
      onBlur={handleBlur}
      renderInput={(params) => (
        <TextField
          {...params}
          name="type"
          label="Art der Belastung"
          required
          error={Boolean(errors.type && touched.type) || false}
          helperText={errors.type && touched.type && errors.type}
        />
      )}
    />
  );
}

export default ReceiptEditType;
