import React, { useCallback, useContext, useMemo } from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Table as TableGrid } from '@devexpress/dx-react-grid-material-ui';
import Table from '../Table';
import columnDef from './columnDef';
import DataContext from '../../context/DataProvider/DataContext';
import { AgentStatus } from '../../types/Agent.types';
import InfoButton from '../InfoButton';
import i18n from './i18n';

function StartSoon() {
  const { agents } = useContext(DataContext);
  const navigate = useNavigate();

  const filteredAgents = useMemo(
    () => agents.filter((agent) => agent.status.id === AgentStatus.inProcess && agent.start_date),
    [agents],
  );

  const TableRow = useCallback(
    ({ row, children, tableRow }: TableGrid.DataRowProps) => (
      <TableGrid.Row
        key={Math.random()}
        tableRow={tableRow}
        row={row}
        // eslint-disable-next-line react/no-children-prop
        children={children}
        onClick={() => {
          navigate(`/agent/${row.agent_id}`);
        }}
        style={{ cursor: 'pointer' }}
      />
    ),
    [],
  );

  return (
    <Card sx={{ minWidth: '800px', width: '30vw' }}>
      <CardContent>
        <Box display="flex">
          <Typography gutterBottom variant="h5" component="div">
            Demnächst starten
          </Typography>
          <InfoButton tooltiptext={i18n.infoText} />
        </Box>
        <Table
          rowComponent={TableRow}
          sortingProp={[{ columnName: 'start_date', direction: 'asc' }]}
          pageSize={5}
          columns={columnDef}
          row={filteredAgents}
        />
      </CardContent>
    </Card>
  );
}

export default StartSoon;
