import { gql } from '@apollo/client';

const updateContractType = gql`
  mutation ($variables: ContractTypeUpdate!, $id: Int!) {
    updateContractType(input: $variables, id: $id) {
      id
      title
      is_default
    }
  }
`;
export default updateContractType;

