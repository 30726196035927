import React, { useEffect } from 'react';
import { Navigate, Route, Routes, redirect } from 'react-router-dom';
import { getCurrentUser, getToken } from './context/CommunicationProvider/authUser';
import SiteLayout from './components/SiteLayout';
import pages, { Page, PagePathname } from './pages';
import Login from './sections/auth/Login';
import Page404 from './pages/Page404/Page404';

interface Props {
  setUserToken: React.Dispatch<React.SetStateAction<string>>;
  setApolloClientToken?: () => void;
}

function Router({ setUserToken, setApolloClientToken }: Props) {
  useEffect(() => {
    const token = getToken();
    if (token) {
      setUserToken(token);
    }
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Navigate to={PagePathname.dashboard} replace />} />
      {pages.map((item: Page) => (
        <Route
          key={Math.random()}
          path={item.path}
          Component={() => {
            if (!getCurrentUser(setApolloClientToken)) redirect('/login');
            return <SiteLayout main={item.render} />;
          }}
        />
      ))}
      <Route
        path="login"
        Component={() => {
          if (getCurrentUser(setApolloClientToken)) redirect(PagePathname.dashboard);
          return <Login />;
        }}
      />
      <Route path="404" element={<Page404 />} />
      <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  );
}

export default Router;
