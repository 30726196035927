import React, { useRef } from 'react';
import Input from '@mui/material/Input';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';

interface Props {
  value: boolean;
  onValueChange: (value: boolean) => void;
}

const BooleanEditor = ({ value, onValueChange }: Props): JSX.Element => {
  const { current: initialValue } = useRef(value);
  if (initialValue !== undefined) {
    return (
      <Select
        input={<Input />}
        value={value ? 'Ja' : 'Nein'}
        onChange={(event) => onValueChange(event.target.value === 'Ja')}
      >
        <MenuItem value="Ja">Ja</MenuItem>
        <MenuItem value="Nein">Nein</MenuItem>
      </Select>
    );
  } else {
    return (
      <FormControl error={value === undefined ? true : false}>
        <Select input={<Input />} value={value} onChange={(event) => onValueChange(event.target.value === 'Ja')}>
          <MenuItem value="Ja">Ja</MenuItem>
          <MenuItem value="Nein">Nein</MenuItem>
        </Select>
        <FormHelperText>{value === undefined ? 'Pflichtfeld' : ''}</FormHelperText>
      </FormControl>
    );
  }
};

export default BooleanEditor;
