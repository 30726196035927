import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import Alert from '@mui/material/Alert';
import { Box, CircularProgress, Snackbar, Typography } from '@mui/material';
import { useMutation } from '@apollo/client';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { default as uploadPDFMutation } from '../../../Apollo/mutations/uploadPDF';

type props = {
  agent_id?: string;
  refetch?: () => void;
};

const UploadFiles = ({ refetch, agent_id }: props) => {
  const [alert, setAlert] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [uploadPDF, { loading }] = useMutation(uploadPDFMutation);

  return (
    <>
      <Snackbar open={alert} autoHideDuration={10000} onClose={() => setAlert(false)}>
        <Alert severity="warning" elevation={6} variant="filled" onClose={() => setAlert(false)}>
          {errMsg}
        </Alert>
      </Snackbar>

      <Dropzone
        accept={{ 'application/pdf': ['.pdf'] }}
        maxFiles={1}
        onDrop={(acceptedFiles, fileRejections) => {
          if (!loading && acceptedFiles.length > 0) {
            uploadPDF({
              variables: {
                pdf: acceptedFiles[0],
                name: acceptedFiles[0].name ?? 'unbekannt',
                agent_id: agent_id ?? null,
              },
            }).then(() => {
              if (refetch) {
                refetch();
              }
            });
          }
          if (fileRejections.length > 0) {
            setAlert(true);
            setErrMsg('Es können nur PDF hochgeladen werden');
          }
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <Box display="flex" justifyContent="center" justifyItems="center">
            <Box {...getRootProps()} style={{ cursor: 'pointer' }} justifyContent="center">
              <input {...getInputProps()} />
              <Box display="flex" justifyContent="center">
                {loading && <CircularProgress />}
                {!loading && <CloudUploadIcon fontSize="large" />}
              </Box>
              <Box justifyContent="center" width="100%">
                <Typography>Drag and Drop or click</Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Dropzone>
    </>
  );
};

export default UploadFiles;
