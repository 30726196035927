import React, { useContext } from 'react';
import TextField from '@mui/material/TextField';
import { Divider, Stack } from '@mui/material';
import DataContext from '../../context/DataProvider/DataContext';
import StartSoon from '../../components/StartSoonAgent';
import BirthdaysOfMonth from '../../components/BirthdaysOfMonth';
import { AgentStatus } from '../../types/Agent.types';
import { Agent } from '../../types/Agent.types';

function DashboardPage() {
  const context = useContext(DataContext);

  const BeraterInsgesamt = context.agents.length;
  const ActiveBerater = context.agents.filter((agent: Agent) => {
    return agent.status.id === AgentStatus.active;
  }).length;
  const GekündigteBerater = context.agents.filter((agent: Agent) => {
    return agent.status.id === AgentStatus.dismissed;
  }).length;
  const InBearbeitungBerater = context.agents.filter((agent: Agent) => {
    return agent.status.id === AgentStatus.inProcess;
  }).length;
  const VerloreneBerater = context.agents.filter((agent: Agent) => {
    return agent.status.id === AgentStatus.failed;
  }).length;

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2} divider={<Divider orientation="vertical" flexItem />}>
        <TextField
          aria-readonly
          variant="outlined"
          margin="normal"
          fullWidth
          id="sub_title"
          label="Berater insgesamt"
          name="sub_title"
          autoComplete="sub_title"
          value={BeraterInsgesamt || 0}
          InputProps={{
            readOnly: true,
          }}
        />

        <TextField
          aria-readonly
          variant="outlined"
          margin="normal"
          fullWidth
          id="sub_title"
          label="Active Berater"
          name="sub_title"
          autoComplete="sub_title"
          value={ActiveBerater || 0}
          InputProps={{
            readOnly: true,
          }}
        />

        <TextField
          aria-readonly
          variant="outlined"
          margin="normal"
          fullWidth
          id="sub_title"
          label="Berater in Bearbeitung"
          name="sub_title"
          autoComplete="sub_title"
          value={InBearbeitungBerater || 0}
          InputProps={{
            readOnly: true,
          }}
        />

        <TextField
          aria-readonly
          variant="outlined"
          margin="normal"
          fullWidth
          id="sub_title"
          label="Gekündigte Berater"
          name="sub_title"
          autoComplete="sub_title"
          value={GekündigteBerater || 0}
          InputProps={{
            readOnly: true,
          }}
        />

        <TextField
          aria-readonly
          variant="outlined"
          margin="normal"
          fullWidth
          id="sub_title"
          label="Verlorene Berater"
          name="sub_title"
          autoComplete="sub_title"
          value={VerloreneBerater || 0}
          InputProps={{
            readOnly: true,
          }}
        />
      </Stack>
      <Stack direction="row" spacing={2} useFlexGap flexWrap={'wrap'}>
        <StartSoon />
        <BirthdaysOfMonth />
      </Stack>
    </Stack>
  );
}
export default DashboardPage;
