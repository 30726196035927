import React from 'react';
import ErrorBoundaries from '../../../components/ErrorBoundaries';
import AllBookingAccountsTable from './components/AllBookingAccountsTable';

function OptionsPage() {
  return (
    <ErrorBoundaries>
      <AllBookingAccountsTable />
    </ErrorBoundaries>
  );
}

export default OptionsPage;
