import React from 'react';
import ErrorBoundaries from '../../../components/ErrorBoundaries';
import ContractTypsTable from './components/ContractTypsTable';

function ContractTypsPage() {
  return (
    <ErrorBoundaries>
      <ContractTypsTable />
    </ErrorBoundaries>
  );
}
export default ContractTypsPage;
