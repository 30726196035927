import React, { useCallback, useEffect, useMemo, useState } from 'react';
import UserContext, { defaultValues } from './UserContext';
import { getCurrentUser } from '../CommunicationProvider/authUser';
import { User } from '../../types/User.types';

interface Props {
  children: React.ReactNode;
}
function UserProvider({ children }: Props): JSX.Element {
  const { Provider } = UserContext;
  const [user, setUser] = useState<User>(defaultValues);
  const [isLoggedIn, setIsLoggedIn] = useState<Boolean>(false);

  useEffect(() => {
    const getUser = getCurrentUser() as User | undefined;
    if (getUser) {
      setUser(getUser);
    }
  }, [isLoggedIn]);

  const login = useCallback((userLogin, passwordLogin) => {
    const request = fetch(`/backlogin?username=${userLogin}&password=${passwordLogin}`)
      .then((res) => res.json())
      .then((res) => {
        localStorage.setItem('token', res.token);
        setIsLoggedIn(true);
        return true;
      })
      .catch((error) => {
        console.error('Login is error:', error);
        setIsLoggedIn(false);
        return false;
      });
    return request;
  }, []);

  const values = useMemo(() => ({ ...defaultValues, ...user, login }), [user, login]);

  return <Provider value={values}>{children}</Provider>;
}

export default UserProvider;
