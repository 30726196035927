import React from 'react';
import ErrorBoundaries from '../../../components/ErrorBoundaries';
import UserTable from './components/UserTable';

function UserPage() {
  return (
    <ErrorBoundaries>
      <UserTable />
    </ErrorBoundaries>
  );
}

export default UserPage;
