import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import ReceiptInput from '../../../../types/ReceiptInput.types';
import { Agent } from '../../../../types/Agent.types';
import { useFormikContext } from 'formik';

type Props = {
  allAgents: Agent[];
  onChange?: (agentId?: string) => void;
};

const ReceiptEditAgent = ({ allAgents, onChange }: Props) => {
  const { setFieldValue, handleBlur, values, errors, touched } = useFormikContext<ReceiptInput>();
  return (
    <Autocomplete
      id="ReceiptEditAgent"
      fullWidth
      options={allAgents}
      getOptionLabel={(agent) =>
        `${agent.agent_id} ${agent.surname} ${agent.name} ${agent.is_small_business === 1 ? 'Kleinunternehmer' : ''}`
      }
      onChange={(e, value) => {
        onChange && onChange(value?.id);
        setFieldValue('agent_id', value?.id || '');
      }}
      value={allAgents.find((item) => item.id === values.agent_id) || null}
      onBlur={handleBlur}
      renderInput={(params) => (
        <TextField
          {...params}
          name="agent_id"
          label="Berater wählen"
          error={Boolean(errors.agent_id && touched.agent_id) || false}
          helperText={errors.agent_id && touched.agent_id && errors.agent_id}
          required
        />
      )}
    />
  );
};

export default ReceiptEditAgent;
