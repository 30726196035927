import { gql } from '@apollo/client';

const getServicesByInteresentId = gql`
  query ($variables: InteresentById!) {
    getServicesByInteresentId(input: $variables) {
      id
      interesent_id
      service_id
      name
      description
      enable
      type
    }
  }
`;

export default getServicesByInteresentId;
