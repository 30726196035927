import { gql } from '@apollo/client';

const updateInteressentContactStatus = gql`
  mutation ($variables: InteresentStatusUpdate!) {
    updateInteresentStatus(input: $variables) {
      id
      title
      date
      checked
    }
  }
`;
export default updateInteressentContactStatus;
