import { useNavigate } from 'react-router-dom';
import { alpha, useTheme } from '@mui/material/styles';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import IconButtonAnimate from '../../animate/IconButtonAnimate';

// ----------------------------------------------------------------------

export default function CreateAgentHeaderAction() {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.grey[700];
  const PRIMARY_DARK = theme.palette.grey[400];
  const navigate = useNavigate();

  return (
    <>
      <IconButtonAnimate
        onClick={() => {
          navigate('/create-agent');
        }}
        sx={{
          p: 0,
          ...{
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
            },
          },
        }}
      >
        <PersonAddAlt1Icon
          sx={{
            fontSize: '40px',
            color: theme.palette.mode === 'dark' ? PRIMARY_DARK : PRIMARY_LIGHT,
          }}
        />
      </IconButtonAnimate>
    </>
  );
}
