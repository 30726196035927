import React from 'react';
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Box,
  TableContainer,
  TablePagination,
  Paper,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const TableSkeleton: React.FC = () => {
  // Anzahl der Reihen, die angezeigt werden sollen
  const rows = 10;

  return (
    <TableContainer component={Paper}>
      {/* Suchleiste und neuer Eintrag Button */}
      <Box display="flex" justifyContent="space-between" alignItems="center" padding={2}>
        <Skeleton variant="rectangular" width={200} height={40} />
        <IconButton>
          <AddIcon />
        </IconButton>
      </Box>

      <Table>
        {/* Tabellenkopf */}
        <TableHead>
          <TableRow>
            <TableCell>
              <Skeleton width={40} />
            </TableCell>
            <TableCell>
              <Skeleton width={100} />
            </TableCell>
            <TableCell>
              <Skeleton width={100} />
            </TableCell>
            <TableCell>
              <Skeleton width={100} />
            </TableCell>
            <TableCell>
              <Skeleton width={150} />
            </TableCell>
            <TableCell>
              <Skeleton width={100} />
            </TableCell>
            <TableCell>
              <Skeleton width={100} />
            </TableCell>
            <TableCell>
              <Skeleton width={150} />
            </TableCell>
          </TableRow>
        </TableHead>

        {/* Tabellenkörper */}
        <TableBody>
          {Array.from({ length: rows }).map((_, index) => (
            <TableRow key={index}>
              <TableCell>
                <IconButton>
                  <EditIcon />
                </IconButton>
                <IconButton>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
              <TableCell>
                <Skeleton width={60} />
              </TableCell>
              <TableCell>
                <Skeleton width={60} />
              </TableCell>
              <TableCell>
                <Skeleton width={60} />
              </TableCell>
              <TableCell>
                <Skeleton width={100} />
              </TableCell>
              <TableCell>
                <Skeleton width={80} />
              </TableCell>
              <TableCell>
                <Skeleton width={80} />
              </TableCell>
              <TableCell>
                <Skeleton width={100} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Pagination Skeleton */}
      <TablePagination
        rowsPerPageOptions={[15]}
        component="div"
        count={0}
        rowsPerPage={15}
        page={0}
        onPageChange={() => {}}
        onRowsPerPageChange={() => {}}
      />
    </TableContainer>
  );
};

export default TableSkeleton;
