import React, { useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ClickAwayListener, IconButton, IconButtonProps, Tooltip } from '@mui/material';

interface Props extends IconButtonProps {
  tooltiptext: string;
}

function InfoButton(props: Props) {
  const { tooltiptext } = props;
  const [isToolTipShowing, setToolTipShowing] = useState(false);
  return (
    <ClickAwayListener
      onClickAway={() => {
        setToolTipShowing(false);
      }}
    >
      <Tooltip placement="top" open={isToolTipShowing} title={tooltiptext}>
        <IconButton
          onClick={() => {
            setToolTipShowing((prev) => !prev);
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        >
          <InfoOutlinedIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  );
}

export default InfoButton;
