import { gql } from '@apollo/client';

const updateCoutry = gql`
  mutation ($variables: CountryUpdate!, $id: Int!) {
    updateCountry(input: $variables, id: $id) {
      id
      title
      is_default
    }
  }
`;
export default updateCoutry;
