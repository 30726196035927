import onlyWhitespaceInString from '../../../../services/onlyWhitespaceInString';

function nameIsIdentical(inputValue, currentValue, listOfContractTypes) {
  let nameIsIdentical = false;
  for (let index = 0; index < listOfContractTypes.length; index++) {
    if (currentValue) {
      if (inputValue === currentValue) {
        return false;
      }
    }
    if (inputValue === listOfContractTypes[index].title) {
      return true;
    }
  }
  return nameIsIdentical;
}

export default function ({ added, changed, rows, refetch, createContractType, updateContractType }) {
  let changedRows;

  if (added) {
    const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
    changedRows = [
      ...rows,
      ...added.map((row, index) => ({
        id: startingAddedId + index,
        ...row,
      })),
    ];

    let currentContract = {
      title: undefined,
      is_default: undefined,
    };

    if (changedRows[rows.length].title) {
      if (changedRows[rows.length].title.length > 255) {
        alert(
          'Vertagsart kann nicht gespeichert werde da die maximale Zeichenanzahl von 255 Zeichen überschritten wurde',
        );
      } else if (onlyWhitespaceInString(changedRows[rows.length].title)) {
        alert('Vertagsart kann nicht gespeichert da es nur aus Leerzeichen besteht');
      } else if (nameIsIdentical(changedRows[rows.length].title, currentContract.title, rows)) {
        alert('Vertagsart kann nicht gespeichert da Titel bereits vorhanden ist');
      } else {
        const currentContractTyps = {
          title: changedRows[rows.length].title.trim(),
          is_default: changedRows[rows.length].is_default,
        };
        if (currentContractTyps.title) {
          createContractType({ variables: { variables: currentContractTyps } }).then((result) => {
            if (result.data) refetch();
          });
        }
      }
    } else {
      alert('Vertagsart kann nicht gespeichert da es kein Titel enthält');
    }
  }
  if (changed) {
    const currentRows = [...rows];
    changedRows = rows.map((row) => (changed[row.id] ? { ...row, ...changed[row.id] } : row));

    let editRowID;
    let editRow = {};
    let currentRow = {};
    for (let i = 0; i < currentRows.length; i += 1) {
      if (currentRows[i] !== changedRows[i]) {
        editRowID = changedRows[i].id;
        currentRow = { title: currentRows[i].title, is_default: changedRows[i].is_default };
        editRow = {
          title: changedRows[i].title,
          is_default: changedRows[i].is_default,
        };
      }
    }

    if (editRow.title) {
      if (editRow.title.length > 255) {
        alert(
          'Vertagsart kann nicht gespeichert werde da die maximale Zeichenanzahl von 255 Zeichen überschritten wurde',
        );
      } else if (onlyWhitespaceInString(editRow.title)) {
        alert('Vertagsart kann nicht gespeichert da es nur aus Leerzeichen besteht');
      } else if (nameIsIdentical(editRow.title, currentRow.title, rows)) {
        alert('Vertagsart kann nicht gespeichert da Titel bereits vorhanden ist');
      } else {
        const editContractTyps = {
          title: editRow.title.trim(),
          is_default: editRow.is_default,
        };
        if (editContractTyps.title) {
          updateContractType({ variables: { variables: editContractTyps, id: editRowID } }).then((result) => {
            if (result.data) refetch();
          });
        }
      }
    } else {
      alert('Vertagsart kann nicht gespeichert da es kein Titel enthält');
    }
  }
}
