export default [
  { columnName: 'agent_id', width: 200 },
  { columnName: 'name', width: 200 },
  { columnName: 'surname', width: 200 },
  { columnName: 'status', width: 200 },
  { columnName: 'address', width: 200 },
  { columnName: 'dismissal_reason', width: 400 },
  { columnName: 'dismissed_at', width: 200 },
  { columnName: 'rank', width: 200 },
  { columnName: 'contract_type', width: 200 },
  { columnName: 'birthday', width: 200 },
  { columnName: 'level', width: 200 },
  { columnName: 'branch_office', width: 200 },
  { columnName: 'manager', width: 200 },
  { columnName: 'phone', width: 200 },
  { columnName: 'mobile', width: 200 },
  { columnName: 'fax', width: 200 },
  { columnName: 'bank_name', width: 200 },
  { columnName: 'email_corp', width: 300 },
  { columnName: 'email_private', width: 200 },
  { columnName: 'zip', width: 200 },
  { columnName: 'city', width: 200 },
  { columnName: 'country', width: 200 },
  { columnName: 'tax_nr', width: 200 },
  { columnName: 'ust_nr', width: 200 },
  { columnName: 'iban', width: 200 },
  { columnName: 'bic', width: 200 },
  { columnName: 'jackplus_id', width: 200 },
  { columnName: 'agent_account_nr', width: 200 },
  { columnName: 'address2', width: 400 },
  { columnName: 'zip2', width: 200 },
  { columnName: 'city2', width: 200 },
  { columnName: 'country2', width: 200 },
  { columnName: 'business_owner', width: 200 },
  { columnName: 'business_registration_date', width: 200 },
  { columnName: 'is_small_business', width: 200 },
  { columnName: 'start_date', width: 200 },
];
