import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import DataContext, { InitialValuesType, initialValues } from './DataContext';
import useRequest from '../../services/useRequest';
import { Agent } from '../../types/Agent.types';
import { BranchOffice } from '../../types/BranchOffice.types';
import { Level } from '../../types/Level.types';
import { Rank } from '../../types/Rank.types';
import { Tax } from '../../types/Tax.types';
import getAllTax from '../../services/requestToServerSchemas/querySchemas/getAllTax';
import { Country } from '../../types/Country.types';
import getAllReceipts from '../../services/requestToServerSchemas/querySchemas/getAllReceipts';
import { Receipt } from 'global-intern-types';
import getAllBookingAccount from '../../services/requestToServerSchemas/querySchemas/getAllBookingAccount';
import { BookingAccount } from '../../types/BookingAccount.types';
import getAllUser from '../../services/requestToServerSchemas/querySchemas/getAllUser';
import { User } from '../../types/User.types';
import { Status } from '../../types/Status.types';
import { ContractType } from '../../types/ContractType.types';
import UserContext from '../UserProvider/UserContext';
import getClosingDataPayrollPeriod from '../../Apollo/queries/getClosingDataPayrollPeriod';
import { getToken } from '../CommunicationProvider/authUser';
import getAllAgents from '../../Apollo/queries/getAllAgents';
import getAllBranchofficeQuery from '../../Apollo/queries/getAllBrachofficeQuery';
import getAllStatus from '../../Apollo/queries/getAllStatus';
import getAllRanks from '../../Apollo/queries/getAllRanks';
import getAllLevels from '../../Apollo/queries/getAllLevels';
import getContractTypes from '../../Apollo/queries/getContractTypes';
import getAllCountrys from '../../Apollo/queries/getAllCountrys';
import { useLoadingContext } from '../LoadingProvider/LoadingContext';

interface Props {
  children: React.ReactNode;
}

const DataContextProvider = ({ children }: Props) => {
  const user = useContext(UserContext);

  const { data: dateLastPayroll } = useQuery(getClosingDataPayrollPeriod);

  const {
    data: agentsResponse,
    refetch: refetchAgents,
    loading: loadingAllAgents,
  } = useQuery<{ getAllAgents: Agent[] }>(getAllAgents);

  const {
    data: levelResponse,
    refetch: refetchLevels,
    loading: loadingLevels,
  } = useQuery<{ getAllLevel: Level[] }>(getAllLevels);

  const {
    data: rankResponse,
    refetch: refetchRank,
    loading: loadingRanks,
  } = useQuery<{ getAllRanks: Rank[] }>(getAllRanks);

  const { response: taxResponse, request: requestTax, isLoading: loadingTax } = useRequest<{ getAllTax: Tax[] }>();

  const {
    data: countryResponse,
    refetch: refetchCountry,
    loading: loadingCountry,
  } = useQuery<{ getAllCountrys: Country[] }>(getAllCountrys);

  const {
    data: branchofficeResponse,
    refetch: refetchBranchoffice,
    loading: loadingBranchoffice,
  } = useQuery<{
    getAllBranchOffice: BranchOffice[];
  }>(getAllBranchofficeQuery);

  const [receipts, setReceipts] = useState<Receipt[]>([]);

  const {
    response: receiptResponse,
    request: requestReceipt,
    isLoading: loadingReceipt,
  } = useRequest<{ getAllReceipts: Receipt[] }>();

  useEffect(() => {
    if (receiptResponse) {
      setReceipts(receiptResponse.getAllReceipts);
    }
  }, [receiptResponse]);

  const {
    response: bookingAccountResponse,
    request: requestBookingAccount,
    isLoading: loadingBookingAccount,
  } = useRequest<{
    getAllBookingAccount: BookingAccount[];
  }>();

  const { response: userResponse, request: requestUser, isLoading: loadingUser } = useRequest<{ getAllUser: User[] }>();

  const {
    data: statusResponse,
    refetch: requestStatus,
    loading: loadingStatus,
  } = useQuery<{ getAllStatus: Status[] }>(getAllStatus);

  const {
    data: contractTypeResponse,
    refetch: refetchContractType,
    loading: loadingContractTypes,
  } = useQuery<{
    getAllContractTypes: ContractType[];
  }>(getContractTypes);

  const { setLoading } = useLoadingContext();

  const reloadData = useCallback(() => {
    const userToken = getToken();
    if (userToken) {
      refetchAgents();
      refetchBranchoffice();
    }
  }, []);

  const loadLevel = useCallback(() => {
    const userToken = getToken();
    if (userToken) {
      refetchLevels();
    }
  }, [loadingLevels]);

  const loadRank = useCallback(() => {
    const userToken = getToken();
    if (userToken) {
      refetchRank();
    }
  }, [loadingRanks]);

  const loadCountry = useCallback(() => {
    const userToken = getToken();
    if (userToken) {
      refetchCountry();
    }
  }, [loadingCountry]);

  const loadTax = useCallback(() => {
    const userToken = getToken();
    if (userToken) {
      requestTax('query', getAllTax, '');
    }
  }, []);

  const loadContractType = useCallback(() => {
    const userToken = getToken();
    if (userToken) {
      refetchContractType();
    }
  }, []);

  const loadReceipt = useCallback(() => {
    const userToken = getToken();
    if (userToken) {
      requestReceipt('query', getAllReceipts, '');
    }
  }, []);

  const loadBookingAccount = useCallback(() => {
    const userToken = getToken();
    if (userToken) {
      requestBookingAccount('query', getAllBookingAccount, '');
    }
  }, []);

  const loadUser = useCallback(() => {
    const userToken = getToken();
    if (userToken) {
      requestUser('query', getAllUser, '');
    }
  }, []);

  const loadStatus = useCallback(() => {
    const userToken = getToken();
    if (userToken) {
      requestStatus();
    }
  }, []);

  const loadBranchOffice = useCallback(() => {
    const userToken = getToken();
    if (userToken) {
      refetchBranchoffice();
    }
  }, [loadingBranchoffice]);

  const changeReceipts = useCallback((receipts: Receipt[]) => {
    setReceipts(receipts);
  }, []);

  useEffect(() => {
    reloadData();
    loadUser();
  }, []);

  const userToken = getToken();

  useEffect(() => {
    loadBookingAccount();
  }, [user]);

  useEffect(() => {
    if (userToken) {
      setLoading(
        loadingAllAgents ||
          loadingLevels ||
          loadingRanks ||
          loadingTax ||
          loadingCountry ||
          loadingBranchoffice ||
          loadingReceipt ||
          loadingBookingAccount ||
          loadingUser ||
          loadingStatus ||
          loadingContractTypes,
      );
    }
  }, [
    loadingAllAgents,
    loadingLevels,
    loadingRanks,
    loadingTax,
    loadingCountry,
    loadingBranchoffice,
    loadingReceipt,
    loadingBookingAccount,
    loadingUser,
    loadingStatus,
    loadingContractTypes,
  ]);

  const newValues: InitialValuesType = useMemo(
    () => ({
      ...initialValues,
      dateLastPayroll: dateLastPayroll?.getClosingDataPayrollPeriod,
      brachOffices: [...(branchofficeResponse?.getAllBranchOffice || [])],
      agents: [...(agentsResponse?.getAllAgents || [])],
      level: [...(levelResponse?.getAllLevel || [])],
      rank: [...(rankResponse?.getAllRanks || [])],
      tax: [...(taxResponse?.getAllTax || [])],
      country: [...(countryResponse?.getAllCountrys || [])],
      receipt: [...receipts],
      bookingAccount: [...(bookingAccountResponse?.getAllBookingAccount || [])],
      user: [...(userResponse?.getAllUser || [])],
      status: [...(statusResponse?.getAllStatus || [])],
      contractType: [...(contractTypeResponse?.getAllContractTypes || [])],
      loaderFun: {
        ...initialValues.loaderFun,
        loadBranchOffice,
        loadLevel,
        loadRank,
        loadTax,
        loadCountry,
        loadReceipt,
        loadBookingAccount,
        loadUser,
        loadStatus,
        loadContractType,
        changeReceipts,
      },
      reloadData,
    }),
    [
      dateLastPayroll,
      branchofficeResponse,
      agentsResponse,
      levelResponse,
      rankResponse,
      taxResponse,
      countryResponse,
      receiptResponse,
      bookingAccountResponse,
      userResponse,
      statusResponse,
      contractTypeResponse,
      receipts,
    ],
  );

  return <DataContext.Provider value={newValues}>{children}</DataContext.Provider>;
};

export default DataContextProvider;
