import { getToken } from '../../../../../context/CommunicationProvider/authUser';
import { url } from '../../../../../services/config/config';
import dateToServices from '../../../../../services/dateToServices';

const addServiceToAgent = async (service, agent_id) => {
  const query = `mutation{
      addServiceToAgent(input:{
          id: ${JSON.stringify(agent_id)},
          service_id: ${service.id},
          price: ${service.default_price},
          description: ${JSON.stringify(service.title)}
          type: ${JSON.stringify(service.type)}
          start_date: "${dateToServices(service.type)}"
      })
    }`;
  const response = await fetch(url, {
    headers: {
      authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({ query }),
  });
  const responseJson = await response.json();
  return responseJson;
};

export default addServiceToAgent;
