function getPayDateForReceipts(dateLastPayroll: string): {
  getMonth: () => number;
  getYear: () => number;
} {
  const currentMonth = new Date().getUTCMonth();
  const dateLastPayrollMonth = new Date(dateLastPayroll).getUTCMonth();
  const currentYear = new Date().getUTCFullYear();
  const dateLastPayrollYear = new Date(dateLastPayroll).getUTCFullYear();

  const getMonth = () => {
    if (currentMonth === dateLastPayrollMonth) {
      return currentMonth + 1;
    }
    return dateLastPayrollMonth + 1;
  };

  const getYear = () => {
    if (currentYear === dateLastPayrollYear) {
      return currentYear;
    }
    return dateLastPayrollYear;
  };
  return {
    getMonth,
    getYear,
  };
}

export default getPayDateForReceipts;
