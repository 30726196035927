import { PrmUser } from '../PrmUser.types';

const PrmUserInit: PrmUser = {
  email: '',
  enabled: true,
  last_login: '',
  password: '',
  roles: '',
  username: '',
};
export default PrmUserInit;

