import removeReceiptById from '../services/removeReceiptById';

const commitChanges = async ({ deleted, row, loaderFun, deleteReceipt }) => {
  if (deleted) {
    const deletedSet = new Set(deleted);
    const setIter = deletedSet.values();
    const setValue = setIter.next().value;
    const idDelete = parseInt(setValue, 10);

    deleteReceipt({ variables: { id: idDelete } }).then((result) => {
      const updatetReceipts = removeReceiptById(row, idDelete);

      if (result.data.deleteReceipt) loaderFun.changeReceipts(updatetReceipts);
    });
  }
};
export default commitChanges;
