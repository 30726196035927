import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useSettingsContext } from '../settings/SettingsContext';
import useResponsive from '../../hooks/useResponsive';
import NavVertical from './nav/NavVertical';
import NavMini from './nav/NavMini';
import NavHorizontal from './nav/NavHorizontal';
import Header from './header';
import Main from './Main';

function SiteLayout({ main }: { main: React.ReactNode }) {
  const { themeLayout } = useSettingsContext();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const isNavHorizontal = themeLayout === 'horizontal';

  const isNavMini = themeLayout === 'mini';

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderNavVertical = <NavVertical openNav={open} onCloseNav={handleClose} />;

  if (isNavHorizontal) {
    return (
      <>
        <Header onOpenNav={handleOpen} />
        {isDesktop ? <NavHorizontal /> : renderNavVertical}
        {main}
      </>
    );
  }

  if (isNavMini) {
    return (
      <>
        <Header onOpenNav={handleOpen} />

        <Box
          sx={{
            display: { lg: 'flex' },
            minHeight: { lg: 1 },
          }}
        >
          {isDesktop ? <NavMini /> : renderNavVertical}

          <Main>{main}</Main>
        </Box>
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={handleOpen} />

      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
        }}
      >
        {renderNavVertical}

        <Main>{main}</Main>
      </Box>
    </>
  );
}

export default SiteLayout;
