const columns = [
  { name: 'title', title: 'Titel' },
  { name: 'domain', title: 'Domain' },
  { name: 'email_postfix', title: 'Email postfix' },
  { name: 'show_in_export', title: 'Im XLM Export' },
  { name: 'logo', title: 'Logo' },
  { name: 'title_for_worker_passport', title: 'Titel Mitarbeiterausweis' },
  { name: 'min_agent_id', title: 'min Berater-Nummer' },
  { name: 'max_agent_id', title: 'max Berater-Nummer' },
  { name: 'is_default', title: 'Standard' },
  { name: 'phone', title: 'Telefon' },
  { name: 'fax', title: 'Fax' },
  { name: 'email', title: 'Email' },
  { name: 'address', title: 'Straße' },
  { name: 'zip', title: 'PLZ' },
  { name: 'city', title: 'Stadt' },
  { name: 'option', title: ' ' },
];

export default columns;
