// export const URL = window.location.host; ReferenceError: window is not defined;

import { ReceiptType } from '../../types/ReceiptInput.types';

export const URL = typeof window !== 'undefined' ? window.location.host : '';

export enum SERVICESTYPE {
  MonthlyService = 'MonthlyService',
  SingleService = 'SingleService',
  YearlyService = 'YearlyService',
}

export const MONTHS = [
  { id: 1, title: 'January' },
  { id: 2, title: 'February' },
  { id: 3, title: 'March' },
  { id: 4, title: 'April' },
  { id: 5, title: 'May' },
  { id: 6, title: 'June' },
  { id: 7, title: 'July' },
  { id: 8, title: 'August' },
  { id: 9, title: 'September' },
  { id: 10, title: 'October' },
  { id: 11, title: 'November' },
  { id: 12, title: 'December' },
];
export const CONTRACTTYPSLIST: { id: number; title: string; value: ReceiptType }[] = [
  { id: 1, title: 'Belastung', value: 'IncomeReceipt' },
  { id: 2, title: 'Gutschrift', value: 'ExpenseReceipt' },
];

export const DEFAULTALERTOBJECT = {
  typeMessage: '',
  alertMessage: '',
  isAlertMessage: false,
};

export const DEFAULTTABLEPAGESIZE = {
  pageSize: 15,
};
