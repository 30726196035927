import { PagePathname } from '../../../pages';
import Agentv2 from '../../../types/Agentv2.types';
import { AgentInputType } from '../../../types/AgentInputType.types';

export enum LocalStorageKeys {
  formDataCreateAgent = 'formData_createAgent',
}

const handleFormChange = (values: Agentv2 | AgentInputType) => {
  if (window.location.pathname === PagePathname.beraterErstellen) {
    localStorage.setItem(LocalStorageKeys.formDataCreateAgent, JSON.stringify(values));
  }
};
export default handleFormChange;
