function deleteUnnecessarySpaces(input: string): string | undefined {
  // Replace: Ersetze mehrere aufeinanderfolgende Leerzeichen durch eines
  // Trim: Entferne Leerzeichen am Anfang und am Ende
  if (input) {
    return input.trim().replace(/\s+/g, ' ');
  }
  return undefined;
}

export default deleteUnnecessarySpaces;
