import React, { Component } from 'react';
import { Box, Typography } from '@mui/material';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

function ErrorMessageLayout() {
  return (
    <>
      <Typography variant="h1" color="textSecondary">
        Error
      </Typography>
      <Box display="flex" alignItems="center">
        <ReportProblemIcon color="error" fontSize="large" />
        <Typography variant="h6" color="error">
          Opps! Something went wrong.
        </Typography>
      </Box>
    </>
  );
}
class ErrorBoundaries extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMessage: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch = (error, errorMessage) => {
    this.setState({ hasError: error, errorMessage: errorMessage });
  };

  render() {
    if (this.state.hasError) {
      return <ErrorMessageLayout />;
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundaries;
