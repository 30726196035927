import BranchofficeInput from '../BranchOfficeInput.types';

const InitOfficeValue: BranchofficeInput = {
  title: '',
  domain: '',
  email_postfix: '',
  show_in_export_xml: 0,
  logo: '',
  title_for_worker_passport: '',
  min_agent_id: 0,
  max_agent_id: 0,
  is_default: false,
  zip: '',
  city: '',
  address: '',
  phone: '',
  fax: '',
  email: '',
};

export default InitOfficeValue;
