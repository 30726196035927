import { gql } from '@apollo/client';

const createRank = gql`
  mutation ($variables: RankInput!) {
    createRank(input: $variables) {
      id
      title
      is_manager
      is_default
    }
  }
`;
export default createRank;
