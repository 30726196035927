function getMonthName(monthNumber: number): string {
  const monthNames = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ];

  if (monthNumber < 0 || monthNumber > 11) {
    throw new Error('Ungültige Monatszahl');
  }

  return monthNames[monthNumber];
}

export default getMonthName;
