function formatDate(date) {
  if (!date) return '';
  const arr = date.split('-');
  return arr[0] + '-' + arr[1];
}

function normalizeServicesById(servicesById) {
  let normalizedServicesById = servicesById.map((item) => {
    item.end_date = formatDate(item.end_date);
    item.start_date = formatDate(item.start_date);
    return {
      id: item.id ? item.id : '',
      start_date: item.start_date ? item.start_date : '',
      end_date: item.end_date ? item.end_date : '',
      agent_service_agent_id: item.agent_service.agent_id ? item.agent_service.agent_id : '',
      agent_service_service_id: item.agent_service.service_id ? item.agent_service.service_id : '',
      agent_service_price: item.agent_service.price ? item.agent_service.price : '',
      agent_service_description: item.agent_service.description ? item.agent_service.description : '',
      agent_service_type: item.agent_service.type ? item.agent_service.type : '',
      service_id: item.agent_service.service.id ? item.agent_service.service.id : '',
      service_default_price: item.agent_service.service.default_price ? item.agent_service.service.default_price : '',
      service_title: item.agent_service.service.title ? item.agent_service.service.title : '',
      service_booking_account: item.agent_service.service.booking_account.title
        ? item.agent_service.service.booking_account.title
        : '',
      service_type: item.agent_service.service.type ? item.agent_service.service.type : '',
      service_tax: item.agent_service.service.tax.percent ? item.agent_service.service.tax.percent : '',
      service_fixed_start_month: item.agent_service.service.fixed_start_month
        ? item.agent_service.service.fixed_start_month
        : '',
    };
  });
  return normalizedServicesById;
}

export default normalizeServicesById;
