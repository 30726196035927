import deleteUnnecessarySpaces from '../../../../services/deleteUnnecessarySpaces';

export default function ({ added, changed, rows, loaderFun, createRank, updateRank }) {
  let changedRows;
  if (added) {
    const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
    changedRows = [
      ...rows,
      ...added.map((row, index) => ({
        id: startingAddedId + index,
        ...row,
      })),
    ];
    const currentRanks = {
      title: deleteUnnecessarySpaces(changedRows[rows.length].title),
      is_manager: changedRows[rows.length].is_manager === 'Yes' ? 1 : 0,
      is_default: changedRows[rows.length].is_default === 'Yes',
    };
    if (currentRanks) {
      createRank({ variables: { variables: currentRanks } }).then((result) => {
        if (result.data.createRank) loaderFun.loadRank();
      });
    }
  }

  if (changed) {
    const currentRows = [...rows];
    changedRows = rows.map((row) => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
    let editRowID;
    let editRow = {};
    for (let i = 0; i < currentRows.length; i += 1) {
      if (currentRows[i] !== changedRows[i]) {
        editRowID = changedRows[i].id;
        editRow = {
          title: deleteUnnecessarySpaces(changedRows[i].title),
          is_manager: changedRows[i].is_manager ? 1 : 0,
          is_default: changedRows[i].is_default,
        };
      }
    }

    if (editRow) {
      updateRank({ variables: { variables: editRow, id: editRowID } }).then((result) => {
        if (result.data.updateRank) loaderFun.loadRank();
      });
    }
  }
}
