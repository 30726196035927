import React, { useContext } from 'react';
import { Grid, MenuItem, Select, FormControl, FormHelperText, InputLabel } from '@mui/material';
import { useFormikContext } from 'formik';
import DataContext from '../../../context/DataProvider/DataContext';
import { AgentInputType } from '../../../types/AgentInputType.types';
import DebounceTextField from '../../../components/DebounceTextfield';
import { AgentStatus } from '../../../types/Agent.types';
import Agentv2 from '../../../types/Agentv2.types';

function AgentFormDeliveryAddress() {
  const { country } = useContext(DataContext);
  const { values, handleChange, errors, handleBlur, initialValues } = useFormikContext<AgentInputType | Agentv2>();

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <DebounceTextField
          data-testid="agent-personaldata-address"
          aria-label="address"
          label="Straße"
          name="address"
          value={values.address}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={errors.address}
          error={Boolean(errors.address) || false}
          fullWidth
          disabled={initialValues.status_id === AgentStatus.dismissed || values.status_id === AgentStatus.failed}
        />
      </Grid>
      <Grid item>
        <DebounceTextField
          data-testid="agent-personaldata-zip"
          aria-label="zip"
          label="PLZ"
          name="zip"
          value={values.zip}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={errors.zip}
          error={Boolean(errors.zip) || false}
          fullWidth
          disabled={initialValues.status_id === AgentStatus.dismissed || values.status_id === AgentStatus.failed}
        />
      </Grid>
      <Grid item>
        <DebounceTextField
          data-testid="agent-personaldata-city"
          aria-label="city"
          label="Ort"
          name="city"
          value={values.city}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={errors.city}
          error={Boolean(errors.city) || false}
          fullWidth
          disabled={initialValues.status_id === AgentStatus.dismissed || values.status_id === AgentStatus.failed}
        />
      </Grid>
      <Grid item>
        <FormControl
          fullWidth
          error={Boolean(errors.country_id) || false}
          disabled={initialValues.status_id === AgentStatus.dismissed || values.status_id === AgentStatus.failed}
        >
          <InputLabel>Land</InputLabel>
          <Select label="Land" name="country_id" value={values.country_id || ''} onChange={handleChange}>
            {country?.map((item) => (
              <MenuItem key={Math.random()} value={item.id}>
                {item.title}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{errors.country_id}</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default AgentFormDeliveryAddress;
