import * as Yup from 'yup';

const stringMax128nullable = Yup.string().max(128, 'max. 128 Zeichen').nullable();
const phoneRegExpPhoneFax = /^(\+)?(?:[0-9] ?){6,14}[0-9]$/;
const emailPostfixRegExp = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const branchOfficeValidationSchema = Yup.object().shape({
  title: Yup.string().max(128, 'max. 128 Zeichen').required('Pflichtfeld'),
  email_postfix: Yup.string()
    .matches(emailPostfixRegExp, 'Postfix ist ungültig')
    .required('Pflichtfeld')
    .typeError('Pflichtfeld'),
  city: stringMax128nullable,
  min_agent_id: Yup.string()
    .test('uniq', 'Pflichtfeld', (value) => {
      if (value === '0') return false;
      return true;
    })
    .length(4, 'Muss vierstellig sein')
    .matches(/^[0-9]+$/, 'Nur Zahlen')
    .required('Pflichtfeld'),
  max_agent_id: Yup.string()
    .test('uniq', 'Pflichtfeld', (value) => {
      if (value === '0') return false;
      return true;
    })
    .required('Pflichtfeld')
    .length(4, 'Muss vierstellig sein')
    .matches(/^[0-9]+$/, 'Nur Zahlen'),
  address: stringMax128nullable,
  zip: Yup.string()
    .matches(/^[0-9]+$/, 'nur Zahlen')
    .min(1, 'min 1 Zeichen')
    .max(9, 'max 9 Zeichen')
    .nullable(),
  phone: Yup.string()
    .matches(phoneRegExpPhoneFax, 'Ungültige Telefonnummer')
    .required('Pflichtfeld')
    .typeError('Pflichtfeld'),
  fax: Yup.string().matches(phoneRegExpPhoneFax, 'Ungültige Faxnummer').nullable(),
  email: Yup.string().email('Ungültige Email').nullable(),
});

export default branchOfficeValidationSchema;
