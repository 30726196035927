import { gql } from '@apollo/client';

const createCountry = gql`
  mutation ($variables: CountryInput!) {
    createCountry(input: $variables) {
      id
      title
      is_default
    }
  }
`;
export default createCountry;
