import { Service } from '../Service.types';

const ServiceInit: Service = {
  booking_account: { id: 1, title: '', booking_account_nr: 860141, external_referance: 1 },
  default_price: 1,
  fixed_start_month: null,
  id: 1,
  tax: { id: 2, code: '', percent: 19 },
  title: '',
  type: '',
};

export default ServiceInit;
