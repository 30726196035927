const columns = [
  { name: 'username', title: 'Benutzername' },
  { name: 'email', title: 'Email' },
  { name: 'enabled', title: 'Aktiviert' },
  { name: 'password', title: 'Passwort' },
  { name: 'last_login', title: 'Letzte Anmeldung' },
  { name: 'roles', title: 'Rolle' },
];
export default columns;

