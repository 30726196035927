import * as Yup from 'yup';

const ReceiptEditValidationSchema = Yup.object().shape({
  author_id: Yup.number().required('Pflichtfeld'),
  agent_id: Yup.string().required('Pflichtfeld'),
  booking_account_id: Yup.number().required('Pflichtfeld'),
  tax_id: Yup.number().required('Pflichtfeld'),
  price: Yup.number().required('Pflichtfeld'),
  description: Yup.string().max(254, 'Eingabe ist zu lange').required('Pflichtfeld'),
  month: Yup.number().min(1, 'zu klein').max(12, 'zu groß').required('Pflichtfeld'),
  year: Yup.number().required('Pflichtfeld'),
  type: Yup.string().required('Pflichtfeld'),
});

export default ReceiptEditValidationSchema;
