import { gql } from '@apollo/client';

const getContractTypes = gql`
  query {
    getAllContractTypes {
      title
      id
      is_default
    }
  }
`;
export default getContractTypes;
