import React from 'react';
import TextField from '@mui/material/TextField';
import { useFormikContext } from 'formik';
import ReceiptInput from '../../../../types/ReceiptInput.types';

function ReceiptEditDescription() {
  const { handleChange, handleBlur, values, errors, touched } = useFormikContext<ReceiptInput>();

  return (
    <TextField
      fullWidth
      name="description"
      id="ReceiptEditDescription"
      label="Beschreibung"
      variant="filled"
      required
      value={values.description || ''}
      onChange={handleChange}
      onBlur={handleBlur}
      error={Boolean(errors.description && touched.description) || false}
      helperText={errors.description && touched.description && errors.description}
    />
  );
}

export default ReceiptEditDescription;
