export default {
  start_date: '',
  end_date: '',
  agent_service_price: '',
  agent_service_description: '',
  agent_service_type: '',
  service_id: '',
  service_default_price: '2',
  service_title: '',
  service_type: '',
  service_tax: '',
  customer_id: '',
};
