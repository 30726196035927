import { gql } from '@apollo/client';

const createBookingAccount = gql`
  mutation ($variables: BookingAccountInput!) {
    createBookingAccount(input: $variables) {
      id
      title
      booking_account_nr
    }
  }
`;
export default createBookingAccount;
