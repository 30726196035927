import { gql } from '@apollo/client';

const createReceipt = gql`
  mutation ($input: ReceiptInput!) {
    createReceipt(input: $input) {
      id
      author_id
      agent_id
      booking_account_id
      tax_id
      price
      description
      month
      year
      type
      exported_at
      accounting_receipt_id
      export_to_accounting_id
      agent {
        surname
        name
        agent_id
      }
    }
  }
`;

export default createReceipt;
