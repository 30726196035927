import { gql } from '@apollo/client';

const updateInteresent = gql`
  mutation ($variables: InteresentUpdate!) {
    updateInteresent(input: $variables) {
      id
      name
      surname
      agent_id
      birthday
      is_male
      phone
      mobile
      email_private
      branch_office_id
      contract_type_id
      city
      address
      zip
      country_id
      notes
      interesentStatus {
        id
        title
        date
        checked
      }
    }
  }
`;
export default updateInteresent;
