import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useLoadingContext } from '../../context/LoadingProvider/LoadingContext';
import { Box, DialogContent, DialogTitle, Dialog } from '@mui/material';

type Props = {
  open?: boolean;
};

const Loading = ({ open }: Props) => {
  const { loading } = useLoadingContext();
  return (
    <Dialog aria-labelledby="loading-dialog" open={open || loading}>
      <DialogTitle id="loading-dialog">Daten werden geladen...</DialogTitle>
      <DialogContent id="loading-dialog">
        <Box display="flex" justifyContent="center" minHeight={100}>
          <CircularProgress />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default Loading;
