import React, { useState, useContext, useRef, useCallback, useEffect, ElementType, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Paper,
  FormControl,
  FormControlLabel,
  Badge,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  FormGroup,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid as GridMUI,
} from '@mui/material';
import columnWidths from './columnsWidths';
import {
  Grid,
  TableColumnVisibility,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableFilterRow,
  TableColumnResizing,
  SearchPanel,
  Toolbar,
  ExportPanel,
} from '@devexpress/dx-react-grid-material-ui';
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  FilteringState,
  IntegratedFiltering,
  SearchState,
  Filter,
  Sorting,
  DataTypeProvider,
} from '@devexpress/dx-react-grid';
import saveAs from 'file-saver';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import BrachOfficeFilterChoice from '../../../components/BranchofficeChoiseButtons/BrachofficeBtnContainer';
import DataContext from '../../../context/DataProvider/DataContext';
import columns from './columns';
import shopifyColumns from './ShopifyColumns';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { AgentStatus } from '../../../types/Agent.types';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { DateRange, ExpandMore } from '@mui/icons-material';
import RowsProvider from './RowsProvider';

// @ts-expect-error
const onSaveFilterXlsx = (workbook) => {
  // @ts-expect-error
  workbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'FilterDataGrid.xlsx');
  });
};
// @ts-expect-error
const onSaveXlsx = (workbook) => {
  // @ts-expect-error
  workbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
  });
};
// @ts-expect-error
const onSaveCSV = (workbook) => {
  // @ts-expect-error
  workbook.csv.writeBuffer().then((buffer) => {
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'shopify.csv');
  });
};

interface PropeFilter {
  type: any;
  restProps: any;
  component: ElementType<any>;
}

const FilterIcon = ({ type, ...restProps }: PropeFilter) => {
  if (type === 'month') return <DateRange {...restProps} />;
  return <TableFilterRow.Icon type={type} {...restProps} />;
};

function AllAgentsTable() {
  const { brachOffices, agents, status, loaderFun } = useContext(DataContext);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageSizes] = useState([10, 25, 50, 100]);
  const [sorting, setSorting] = useState<Sorting[]>([{ columnName: 'branch_office', direction: 'asc' }]);
  const [defaultColumnWidths] = useState(columnWidths);
  const [defaultHiddenColumnNames] = useState(['id']);
  const [filters, setFilters] = useState<Filter[]>([]);
  const [isDismissedAgentFilter, setIsDismissedAgentFilter] = useState(false);
  const [dateColumns] = useState(['birthday', 'start_date', 'dismissed_at', 'business_registration_date']);
  const [dateFilterOperations] = useState(['month', 'contains', 'startsWith', 'endsWith']);
  const [agentsStatus, setAgentStatus] = useState(3);
  const [agentsOffOffice, setAgentsOffOffice] = useState(1);
  const [filteringColumnExtensions] = useState([
    {
      columnName: 'birthday',
      predicate: (value: any, filter: any, row: any) => {
        if (!filter.value.length) return true;
        if (filter && filter.operation === 'month') {
          const month = parseInt(value.split('-')[1], 10);
          return month === parseInt(filter.value, 10);
        }
        return IntegratedFiltering.defaultPredicate(value, filter, row);
      },
    },
    {
      columnName: 'start_date',
      predicate: (value: any, filter: any, row: any) => {
        if (!filter.value.length) return true;
        if (filter && filter.operation === 'month') {
          if (value === null) {
            return false;
          }
          const month = parseInt(value.split('-')[1], 10);
          return month === parseInt(filter.value, 10);
        }
        return IntegratedFiltering.defaultPredicate(value, filter, row);
      },
    },
    {
      columnName: 'dismissed_at',
      predicate: (value: any, filter: any, row: any) => {
        if (!filter.value.length) return true;
        if (filter && filter.operation === 'month') {
          if (value === null) {
            return false;
          }
          const month = parseInt(value.split('-')[1], 10);
          return month === parseInt(filter.value, 10);
        }
        return IntegratedFiltering.defaultPredicate(value, filter, row);
      },
    },
    {
      columnName: 'business_registration_date',
      predicate: (value: any, filter: any, row: any) => {
        if (!filter.value.length) return true;
        if (filter && filter.operation === 'month') {
          if (value === null) {
            return false;
          }
          const month = parseInt(value.split('-')[1], 10);
          return month === parseInt(filter.value, 10);
        }
        return IntegratedFiltering.defaultPredicate(value, filter, row);
      },
    },
  ]);
  const [exportedRows, setExportedRows] = useState([]);
  const [hiddenColumnNames] = useState([]);
  const [columnsM, setColumns] = useState(columns);
  const [checkedColumns, setCheckedColumns] = useState(columns.map((col) => col.name));

  const refExportFilter = useRef<typeof GridExporter>(null);
  const refExportApp = useRef<typeof GridExporter>(null);
  const refExportShopify = useRef<typeof GridExporter>(null);

  useEffect(() => {
    if (!status) {
      loaderFun.loadStatus();
    }
  }, []);

  const startFilterExport = useCallback(() => {
    refExportFilter.current?.exportGrid({ selectedOnly: true });
  }, [refExportFilter]);

  const startExport = useCallback(() => {
    refExportApp.current?.exportGrid();
  }, [refExportApp]);

  const startShopifyExport = useCallback(() => {
    refExportShopify.current?.exportGrid();
  }, [refExportShopify]);

  const TableRow = useCallback(
    ({ row, children, tableRow }: Table.DataRowProps) => (
      <Table.Row
        key={Math.random()}
        tableRow={tableRow}
        row={row}
        // eslint-disable-next-line react/no-children-prop
        children={children}
        onClick={() => {
          navigate(`/agent/${row.agent_id}`);
        }}
        style={{ cursor: 'pointer' }}
      />
    ),
    [],
  );

  const changeCurrentPage = useCallback((value) => {
    setCurrentPage(value);
  }, []);
  const changePageSize = useCallback((value) => {
    setPageSize(value);
  }, []);

  const setBrancheOfficeFilter = useCallback(
    (officeTitle) => {
      const currentFilterList = filters;
      const index = currentFilterList.findIndex((filter) => filter.columnName === 'branch_office');
      if (index !== -1) {
        currentFilterList[index] = { columnName: 'branch_office', operation: 'equal', value: officeTitle };
        setFilters([...currentFilterList]);
      } else {
        setFilters([...filters, { columnName: 'branch_office', operation: 'equal', value: officeTitle }]);
      }
    },
    [filters],
  );

  const allFilterReset = useCallback(() => {
    setFilters([]);
    setIsDismissedAgentFilter(false);
  }, []);

  const ToolbarRootBase = useCallback(
    ({ children, style }) => (
      <Toolbar.Root>
        <Stack direction={'row'} width={'100%'} gap={2}>
          <BrachOfficeFilterChoice
            brachofficeData={brachOffices}
            setBrancheOfficeFilter={setBrancheOfficeFilter}
            allFilterReset={allFilterReset}
          />
          {children}
        </Stack>
      </Toolbar.Root>
    ),
    [agents, isDismissedAgentFilter, status],
  );

  const exportMenu = useCallback(
    () => (
      <Box display="flex" flexDirection="column">
        <Button onClick={startExport}>Alle</Button>
        <Button onClick={startFilterExport}>Filter</Button>
        <Button onClick={startShopifyExport}>Shopify</Button>
      </Box>
    ),
    [],
  );

  // Memoize visibleColumns to prevent unnecessary re-renders
  const visibleColumns = useMemo(() => {
    /* @ts-ignore */
    return columns.filter((column) => !hiddenColumnNames.includes(column.name));
  }, [columns, hiddenColumnNames]);

  const activeAgents = useMemo(() => {
    return agents.filter((agents) => agents.status.id === AgentStatus.active);
  }, [agents]);

  const dismissedAgents = useMemo(() => {
    return agents.filter((agents) => agents.status.id === AgentStatus.dismissed);
  }, [agents]);

  const imProcessAgents = useMemo(() => {
    return agents.filter((agents) => agents.status.id === AgentStatus.inProcess);
  }, [agents]);

  const agentsFailed = useMemo(() => {
    return agents.filter((agents) => agents.status.id === AgentStatus.failed);
  }, [agents]);

  function agentsOnStatus(statusID: number) {
    switch (statusID) {
      case AgentStatus.active:
        return activeAgents;
      case AgentStatus.dismissed:
        return dismissedAgents;
      case AgentStatus.inProcess:
        return imProcessAgents;
      case AgentStatus.failed:
        return agentsFailed;
      default:
        return activeAgents;
    }
  }

  const agentsOfOffice = useMemo(() => {
    return agentsOnStatus(agentsStatus).filter((agents) => {
      return agents.branch_office.id === agentsOffOffice;
    });
  }, [agentsOffOffice, agentsStatus, agents, visibleColumns]);

  useMemo(() => {
    let defaultColumnsName = [
      'dismissal_reason',
      'dismissed_at',
      'business_registration_date',
      'branch_office',
      'business_owner',
      'contract_type',
      'is_small_business',
      'address2',
      'fax',
      'status',
      'rank',
      'is_small_business',
      'start_date',
    ];

    const defaultColumns = columns.filter((col) => {
      return !defaultColumnsName.includes(col.name);
    });
    setColumns(defaultColumns);
    const checkedDefauls = checkedColumns.filter((name) => {
      return !defaultColumnsName.includes(name);
    });
    setCheckedColumns(checkedDefauls);
  }, []);

  const handleCheckboxChange = useMemo(() => {
    return (columnName: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        // Füge die Spalte an ihrer ursprünglichen Position hinzu
        const columnToAdd = columns.find((col) => col.name === columnName);
        if (columnToAdd) {
          setColumns((prevColumns) => {
            const updatedColumns = [...prevColumns, columnToAdd];
            return updatedColumns.sort(
              (a, b) =>
                columns.findIndex((col) => col.name === a.name) - columns.findIndex((col) => col.name === b.name),
            );
          });
          setCheckedColumns((prev) => [...prev, columnName]);
        }
      } else {
        // Entferne die Spalte, wenn Checkbox deaktiviert ist
        setColumns((prevColumns) => prevColumns.filter((col) => col.name !== columnName));
        setCheckedColumns((prev) => prev.filter((name) => name !== columnName));
      }
    };
  }, [columns, checkedColumns]);

  return (
    <>
      <Box>
        <Accordion
          sx={{
            border: '1px solid rgba(255, 255, 255, 0.23)',
            borderRadius: '4px',
            padding: '8px',
            boxShadow: 'none',
          }}
        >
          <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1-content" id="panel1-header">
            <GridMUI container alignItems="center" justifyContent="space-between">
              <GridMUI item>
                <Stack direction="row" spacing={2}>
                  <Badge badgeContent={checkedColumns.length} color="secondary">
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>Sichtbar</Typography>
                      <VisibilityIcon />
                    </Stack>
                  </Badge>
                  <Badge badgeContent={columns.length - checkedColumns.length} color="primary">
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>Unsichtbar</Typography>
                      <VisibilityOffIcon />
                    </Stack>
                  </Badge>
                </Stack>
              </GridMUI>
              <GridMUI item>
                <Typography>Spaltenauswahl</Typography>
              </GridMUI>
            </GridMUI>
          </AccordionSummary>
          <AccordionDetails>
            <FormGroup
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(8, 1fr)',
                gap: '10px',
              }}
            >
              {columns.map((column) => (
                <FormControlLabel
                  key={column.name}
                  control={
                    <Checkbox
                      checked={checkedColumns.includes(column.name)}
                      onChange={handleCheckboxChange(column.name)}
                      name={column.name}
                    />
                  }
                  label={column.title}
                />
              ))}
            </FormGroup>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Stack
        paddingBottom={2}
        direction={'row'}
        alignItems="center"
        justifyContent="space-evenly"
        gap={5}
        paddingTop={2}
      >
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Firma</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={agentsOffOffice}
            label="Firma"
            onChange={(event) => {
              setAgentsOffOffice(event.target.value as number);
            }}
          >
            {brachOffices.map((office) => {
              return (
                <MenuItem key={office.id} value={office.id}>
                  {office.title}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Berater Status</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={agentsStatus}
            label="Berater Status"
            onChange={(event) => {
              setAgentStatus(event.target.value as number);
            }}
          >
            {status
              .filter((statis) => statis.id !== 8 && statis.id !== 6 && statis.id !== 4)
              .map((stati) => {
                return <MenuItem value={stati.id}>{stati.title}</MenuItem>;
              })}
          </Select>
        </FormControl>
      </Stack>
      <Paper>
        <Grid rows={agentsOfOffice} columns={columnsM}>
          {/* <NumberTypeProvider /> */}
          <DataTypeProvider for={dateColumns} availableFilterOperations={dateFilterOperations} />
          <SearchState defaultValue="" />
          <FilteringState filters={filters} onFiltersChange={setFilters} />
          <IntegratedFiltering columnExtensions={filteringColumnExtensions} />
          <RowsProvider onRowsUpdate={setExportedRows} />
          <SortingState sorting={sorting} onSortingChange={setSorting} />
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={changeCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={changePageSize}
          />
          <IntegratedPaging />
          <IntegratedSorting />
          <Table rowComponent={TableRow} />
          <TableColumnResizing columnWidths={defaultColumnWidths} />
          <TableHeaderRow showSortingControls />
          <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />
          {/* @ts-ignore */}
          <TableFilterRow showFilterSelector iconComponent={FilterIcon} messages={{ month: 'Month equals' }} />
          <Toolbar rootComponent={ToolbarRootBase} />
          <SearchPanel />

          <PagingPanel pageSizes={pageSizes} />
          <ExportPanel menuItemComponent={exportMenu} />
        </Grid>

        <GridExporter ref={refExportFilter} rows={exportedRows} columns={columnsM} onSave={onSaveFilterXlsx} />
        <GridExporter ref={refExportApp} rows={agentsOfOffice} columns={visibleColumns} onSave={onSaveXlsx} />
        <GridExporter ref={refExportShopify} rows={agentsOfOffice} columns={shopifyColumns} onSave={onSaveCSV} />
      </Paper>
    </>
  );
}

export default AllAgentsTable;
