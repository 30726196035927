import { gql } from '@apollo/client';

export default gql`
  query CountysQuery {
    getAllCountrys {
      title
      id
      is_default
    }
  }
`;
