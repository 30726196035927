import { gql } from '@apollo/client';

const updateLevel = gql`
  mutation ($variables: LevelUpdate!, $id: Int!) {
    updateLevel(input: $variables, id: $id) {
      id
      title
      is_default
    }
  }
`;
export default updateLevel;
