import React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import getContractTypes from '../../../../Apollo/queries/getContractTypes';
import Table from '../../../../components/Table';
import columns from './columnDefenition';
import ContractTypesInit from '../../../../types/initValues/ContractTypes.init';
import BooleanEditor from './components/BooleanEditor';
import BooleanFormatter from './services/booleanFormater';
import TextEditor from './components/TextEditor';
import createContractTypes from '../../../../Apollo/mutations/createContractTypes';
import updateContractTypeQuery from '../../../../Apollo/mutations/updateContractType';
import commitChanges from './commitChanges';
import AlertMessage from '../../../../components/AlertMessage';

const booleanColumns = ['is_default'];

function BooleanTypeProvider() {
  return (
    <DataTypeProvider formatterComponent={BooleanFormatter} editorComponent={BooleanEditor} for={booleanColumns} />
  );
}

const textColumns = ['title'];

function TextTypeProvider() {
  return <DataTypeProvider editorComponent={TextEditor} for={textColumns} />;
}
const Provider = [BooleanTypeProvider, TextTypeProvider];

export default function ContractTypsTable() {
  const [createContractType, { data: dataCreateContractType, error: errorCreateContractType }] =
    useMutation(createContractTypes);
  const [updateContractType, { data: dataUpdateContractType, error: errorUpdateContractType }] =
    useMutation(updateContractTypeQuery);

  const { data: ContractTypes, refetch } = useQuery(getContractTypes);
  const editingStateColumnExtensions = [{ columnName: 'id', editingEnabled: false }];
  return (
    <>
      <Table
        row={ContractTypes ? ContractTypes?.getAllContractTypes : ContractTypesInit}
        columns={columns}
        defaultColumnWidths={columns.map((column) => ({ columnName: column.name, width: 'auto' }))}
        onCommitChanges={({ added, changed }) =>
          commitChanges({
            added,
            changed,
            rows: ContractTypes?.getAllContractTypes,
            refetch,
            createContractType,
            updateContractType,
          })
        }
        columnExtensions={editingStateColumnExtensions}
        dataTypeProvider={Provider}
        editable
        showAddCommand
        showEditCommand
      />
      <AlertMessage
        typeMessage={
          (dataCreateContractType && !errorCreateContractType && 'success') ||
          (dataUpdateContractType && !errorUpdateContractType && 'success')
        }
        alertMessage={
          (dataCreateContractType && !errorCreateContractType && 'Vertragsart wurde erfolgreich erstellt') ||
          (dataUpdateContractType && !errorUpdateContractType && 'Vertragsart erfolgreich geändert')
        }
        isAlertMessage={
          (!!dataCreateContractType && !errorCreateContractType) ||
          (!!dataUpdateContractType && !errorUpdateContractType)
        }
      />
    </>
  );
}
