import React from 'react';
import { Box } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpansionPanelDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CustomSwitchComponent from './CustomSwitchComponent';
import { Service } from '../../../types/Service.types';

type Props = {
  titleGroup: string;
  servicesList: Service[];
  agentServices: Service[];
  agentId: string;
};

function GroupServiceItem({ titleGroup, servicesList, agentServices, agentId }: Props) {
  const listItems = servicesList.map((service) => {
    return (
      <Box
        key={service.id}
        sx={{
          borderBottom: '1px solid rgba(0, 0, 0, .1)',
        }}
      >
        <CustomSwitchComponent service={service} agentServices={agentServices} agentId={agentId} />
      </Box>
    );
  });

  return (
    <Accordion>
      <AccordionSummary
        sx={{
          root: {
            marginLeft: '8px',
            backgroundColor: '#F4F7FA',
            borderBottom: '1px solid rgba(0, 0, 0, .2)',
            marginBottom: 0,
            minHeight: 52,
            '&$expanded': {
              minHeight: 52,
            },
          },
          content: {
            '&$expanded': {
              margin: '12px 0',
            },
          },
        }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <AssignmentTurnedInIcon
          sx={{
            fontSize: '28px',
            color: '#BDBDBD',
            paddingRight: '5px',
          }}
        />
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {titleGroup}
        </Typography>
      </AccordionSummary>
      <ExpansionPanelDetails>
        <FormControl component="fieldset">
          <FormGroup aria-label="position">{listItems}</FormGroup>
        </FormControl>
      </ExpansionPanelDetails>
    </Accordion>
  );
}

export default GroupServiceItem;
