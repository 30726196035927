import { Service } from '../../../../types/Service.types';

const columns = [
  { name: 'title', title: 'Titel' },
  { name: 'default_price', title: 'Standart Preis €' },
  { name: 'type', title: 'Typ' },
  { name: 'tax', title: 'Steuersatz %' },
  {
    name: 'fixed_start_month',
    title: 'Startmonat',
    getCellValue: (row: Service) => {
      if (row.type === 'YearlyService') {
        return row.fixed_start_month;
      }
      return ' ';
    },
  },
  { name: 'booking_account', title: 'Buchungskonto' },
];

export default columns;
