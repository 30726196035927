export interface Country {
  id: number;
  title: string;
  is_default: boolean | null;
}
export enum Laender {
  deutschland = 1,
  italien = 2,
  spanien = 3,
  oesterreich = 4,
  litauen = 5,
}

export const COUNTRYCODE = {
  [Laender.deutschland]: { id: 1, countryName: 'Deutschland', code: 'DE' },
  [Laender.italien]: { id: 2, countryName: 'Italien', code: 'IT' },
  [Laender.spanien]: { id: 3, countryName: 'Spanien', code: 'ES' },
  [Laender.oesterreich]: { id: 4, countryName: 'Österreich', code: 'AT' },
  [Laender.litauen]: { id: 5, countryName: 'Litauen', code: 'LT' },
};
