import React from 'react';
import { MaintenanceIllustration } from '../../components/illustrations';
import { Stack, Typography } from '@mui/material';

function Alert() {
  return (
    <Stack direction="column" justifyContent="center" alignItems="center" spacing={5}>
      <Typography variant="button" display="block" gutterBottom sx={{ fontSize: 20 }}>
        Ein Fehler ist augetreten
      </Typography>
      <MaintenanceIllustration
        sx={{
          height: 260,
          my: { xs: 5, sm: 10 },
        }}
      />
    </Stack>
  );
}

export default Alert;
