import React, { useContext } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Icon } from 'leaflet';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Tooltip } from '@mui/material';
import 'leaflet/dist/leaflet.css';
import mapPin from './pin.svg';
import DataContext from '../../context/DataProvider/DataContext';
import { Agent, AgentStatus } from '../../types/Agent.types';

const Pin = new Icon({
  iconUrl: mapPin,
  iconSize: [32, 32],
});

function Map() {
  const { agents } = useContext(DataContext);
  const agentsWithUnknownCoordinates: Agent[] = [];
  const agentsWithCoordinates = agents.filter((agent) => {
    if (agent.lat && agent.lon && agent.status.id === AgentStatus.active) {
      return agent;
    }
    if ((!agent.lat || !agent.lon) && agent.status.id === AgentStatus.active) {
      agentsWithUnknownCoordinates.push(agent);
    }
    return null;
  });

  return (
    <>
      <Box>
        <Tooltip title="Aktive Anzahl der Berater mit ermittelten Koordinaten für die hinterlegten Adressen">
          <InfoIcon fontSize="small" />
        </Tooltip>
        <span>Aktive Berater: {agentsWithCoordinates.length}</span>
      </Box>
      <Box>
        <Tooltip
          title={
            <>
              {agentsWithUnknownCoordinates.map((agent) => (
                <Box>
                  <span> {agent.agent_id}</span>
                  <span> {agent.name}</span>
                  <span> {agent.surname}</span>
                </Box>
              ))}
            </>
          }
        >
          <InfoIcon fontSize="small" />
        </Tooltip>
        <span>unbekannte Berater Adressen: {agentsWithUnknownCoordinates.length}</span>
      </Box>

      <MapContainer
        // Deutschland Centrum 51.165689328075516, 10.45151549658858
        center={[51.165689328075516, 10.45151549658858]}
        zoom={6}
        scrollWheelZoom
        style={{ height: '80vh', width: '100%' }}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {agentsWithCoordinates.map((agent, index) => (
          <Marker
            // eslint-disable-next-line react/no-array-index-key
            key={`${Math.random()}${index}`}
            position={[parseFloat(agent.lat || ''), parseFloat(agent.lon || '')]}
            icon={Pin}
          >
            <Popup>{`${agent.agent_id} ${agent.name} ${agent.surname}, ${agent.zip} ${agent.city}, ${agent.address} `}</Popup>
          </Marker>
        ))}
      </MapContainer>
    </>
  );
}

export default Map;
