import { gql } from '@apollo/client';

const updateInteressentServices = gql`
  mutation ($variables: [ServiceInteresentUpdate!]) {
    multiUpdateServiceInteresent(input: $variables) {
      id
      interesent_id
      service_id
      name
      description
      enable
    }
  }
`;

export default updateInteressentServices;
