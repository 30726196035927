import React from 'react';
import ErrorBoundaries from '../../../components/ErrorBoundaries';
import AllCoutrysTable from './components/AllCountrysTable';

function CoutrysPage() {
  return (
    <ErrorBoundaries>
      <AllCoutrysTable />
    </ErrorBoundaries>
  );
}
export default CoutrysPage;
