import { gql } from '@apollo/client';

const getUserProjectById = gql`
  query ($id: String!) {
    getUsersProjectByID(id: $id) {
      id
      project {
        id
        title
        is_enable
      }
      start_date
      end_date
      is_enable
      updatedAt
    }
  }
`;

export default getUserProjectById;
