import { gql } from '@apollo/client';

export default gql`
  query LevelQuery {
    getAllLevel {
      title
      id
      is_default
    }
  }
`;
