import React from 'react';
import CommunicationContext, { defaultValues } from './CommunicationContext';

interface Props {
  children: React.ReactNode;
}

function CommunicationProvider({ children }: Props) {
  const { Provider } = CommunicationContext;

  return <Provider value={defaultValues}>{children}</Provider>;
}

export default CommunicationProvider;
